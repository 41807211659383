import React from "react";
import Banner from "../../images/Home Page Background 1.svg";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import Carousel from "react-multi-carousel";
import { useScrollPosition } from "../../hooks/useScrollPosition";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const faqs = [
  {
    question: "Standard Ferry Version",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: "VIP Shuttle",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: "Battling Fishing",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  // More questions...
];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    paritialVisibilityGutter: 20,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 20,
  },
};

export default function Boat() {
  const scrollPosition = useScrollPosition();

  const handledivClick = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };
  return (
    <section className="container mx-auto py-10">
      <div className="container mt-4  mx-auto grid mb-5 grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8">
        <Link
          to="/boat/battheli-38/passenger-version"
          // onClick={handledivClick}
          className="flex-shrink-0 grid grid-cols-1 gap-y-3  "
        >
          <div className="bg-transparent p-2 group aspect-w-10 aspect-h-7 block w-full overflow-hidden bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
            <img
              src="../../../images/HomePage/PassengerLink.jpg"
              alt=""
              className="rounded-lg w-full  border border-gray-200 shadow-md my-auto pointer-events-none object-cover group-hover:opacity-75"
            />
            <div className="flex gap-x-2 text-lg leading-6 text-gray-900 capitalize py-2">
              Passenger Shuttle
            </div>
            <p className="text-gray-500">
              Unveiling versatility at Sea: set sail with Battheli 38 Passenger
              vessel. Navigate its features today!
            </p>
          </div>
        </Link>
        <Link
          to="/boat/battheli-38/sports-fishing"
          className="flex-shrink-0 grid grid-cols-1 gap-y-3  "
        >
          <div className="bg-transparent p-2 group aspect-w-10 aspect-h-7 block w-full overflow-hidden bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
            <img
              src="../../../images/HomePage/FishingLink.jpg"
              alt=""
              className="rounded-lg pointer-events-none object-cover group-hover:opacity-75"
            />
            <div className="flex gap-x-2 text-lg leading-6 text-gray-900 capitalize py-2">
              Fishing boat
            </div>
            <p className="text-gray-500">
              Plot a cource for adventure! Explore the Battheli 38 fishing boat
              for your next catch
            </p>
          </div>
        </Link>
        <Link
          to="/cox" // onClick={handledivClick}
          className="flex-shrink-0 grid grid-cols-1 gap-y-3  "
        >
          <div className="bg-transparent p-2 group aspect-w-10 aspect-h-7 block w-full overflow-hidden bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
            <img
              src="../../../images/HomePage/CoxLink.jpg"
              alt=""
              className="rounded-lg border border-gray-200 shadow-md my-auto pointer-events-none object-cover group-hover:opacity-75"
            />
            <div className="flex gap-x-2 text-lg leading-6 text-gray-900 capitalize py-2">
              COX outboard engines
            </div>
            <p className="text-gray-500">
              Explore the careful engineering and flawless details COX designers
              put into this pwoerful outboard motor.
            </p>
          </div>
        </Link>
      </div>
    </section>
  );
}
