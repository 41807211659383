/* This example requires Tailwind CSS v2.0+ */
import { Link, useLocation } from "react-router-dom";
import React, { Fragment, useState, useCallback, useEffect } from "react";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import {
  BookOpenIcon,
  BriefcaseIcon,
  ChevronUpIcon,
  GlobeAltIcon,
  InformationCircleIcon,
  NewspaperIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  UsersIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import { Popover, Transition, Disclosure, Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { LuSailboat } from "react-icons/lu";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";

const firstResponse = [
  {
    name: "Fire & Resuce",
    to: "/designs/fire-response/fire-&-resuce",
    icon: LuSailboat,
  },
  {
    name: "Sea Amublance",
    to: "/designs/fire-response/sea-ambulance",
    icon: LuSailboat,
  },
];

const security = [
  // { name: "Logistics", to: "/designs/kf38/logistics", icon: UsersIcon },
  // {
  //   name: "VIP Security & Transfer",
  //   to: "/designs/kf38/vip-security-vessel",
  //   icon: UsersIcon,
  // },
  // { name: "Interceptor", to: "/designs/kf38/interceptor", icon: UsersIcon },

  // {
  //   name: "Search & Resuce",
  //   to: "/designs/kf38/sea-ambulance",
  //   icon: UsersIcon,
  // },
  {
    name: "KF 38 Habour Patrol",
    to: "/designs/security/habour-patrol",
    icon: LuSailboat,
  },
  {
    name: "KF 38 Full Cabin Security Vessel",
    to: "/designs/security/kf38-full-cabin-security-vessel",
    icon: LuSailboat,
  },

  {
    name: "KF 38 Half Cabin Security Vessel",
    to: "/designs/security/kf38-half-cabin-security-vessel",
    icon: LuSailboat,
  },

  {
    name: "KF 38 Troop Carrier",
    to: "/designs/security/troop-carrier-1",
    icon: LuSailboat,
  },
  {
    name: "Pilot Boat",
    to: "/designs/security/pilot-boat",
    icon: LuSailboat,
  },
  // {
  //   name: "Habour Craft",
  //   to: "/designs/kf38/habour-craft",
  //   icon: UsersIcon,
  // },
];

const cargo = [
  // { name: "65FT CARGO DHOANI", to: "#", icon: InformationCircleIcon },
  {
    name: "99FT CARGO DHOANI",
    to: "/designs/cargo/99ft-cargo-dhoani",
    icon: LuSailboat,
  },
];

const fishingExcursion = [
  {
    name: "55FT DIVING & EXCURSION DHOANI",
    to: "/designs/fishing-and-excursion/55ft-diving-&-excursion-dhoani",
    icon: LuSailboat,
  },
  {
    name: "BATTHELI FISHING",
    to: "/designs/fishing-and-excursion/fishing",
    icon: LuSailboat,
  },
];

const passenger = [
  {
    name: "Vip Shuttle",
    to: "/designs/passenger/vip-shuttle",
    icon: LuSailboat,
  },
  {
    name: "Passenger version",
    to: "/designs/passenger/passenger-version",
    icon: LuSailboat,
  },
  {
    name: "Passenger craft 55",
    to: "/designs/passenger/passenger-craft-55",
    icon: LuSailboat,
  },
];

const solutions = [
  {
    name: "Passenger Version",
    description: "Travel with Comfort",
    href: "/boat/battheli-38/passenger-version",
    icon: LuSailboat,
  },
  {
    name: "VIP Shuttle",
    description: "Luxury Travel",
    href: "/boat/battheli-38/vip-shuttle",
    icon: LuSailboat,
  },
  {
    name: "Sports Fishing",
    description: "Fishing in Style",
    href: "/boat/battheli-38/sports-fishing",
    icon: LuSailboat,
  },
];

const engine = [
  {
    name: "COX",
    description: "Best Outboard Diesel Engine",
    href: "/cox",
    icon: LuSailboat,
  },
  {
    name: "Hyundai",
    description: "Engines and Generators",
    href: "/hyundai",
    icon: LuSailboat,
  },
];

const callsToAction = [
  { name: "Watch videos", href: "#", icon: PlayCircleIcon },
  { name: "Contact", href: "#", icon: PhoneIcon },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function NewMega() {
  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);
  const [openDropdowns, setOpenDropdowns] = React.useState<number[]>([]);
  const [openDropdowns1, setOpenDropdowns1] = useState<number[]>([]);
  const [activeDropdown1, setActiveDropdown1] = useState<number | null>(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDesktopMenuOpen, setIsDeskopMenuOpen] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isPopoverDesignOpen, setIsPopoverDesignOpen] = useState(false);
  const location = useLocation();
  let [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    message: "",
  });
  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const apiUrl = "http://api.boatrain.com/api/send-email";
    const currentUrl = location.pathname;

    // Convert the data object to a URL-encoded string
    const urlEncodedData = new URLSearchParams({
      email: formData.email,
      message: formData.message,
      phone: formData.phone,
      link: currentUrl,
    }).toString();

    // Update the Content-Type in headers
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    axios
      .post(apiUrl, urlEncodedData, { headers })
      .then((response) => {
        toast.success("Email sent successfully!");
        setIsOpen(false);
      })
      .catch((error) => {
        toast.error("Error sending email. Please try again.");
      });
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  const handlePopoverOpen = () => {
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  const handlePopoverDesignOpen = () => {
    setIsPopoverDesignOpen(true);
  };

  const handlePopoverDesignClose = () => {
    setIsPopoverDesignOpen(false);
  };

  const handlePopoverBoatOpen = () => {
    setPopoverOpen(true);
  };
  const handlePopoverBoatClose = () => {
    setPopoverOpen(false);
  };

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const toggleDropdown = (index: number) => {
    if (activeDropdown === index) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(index);
    }
    if (openDropdowns.includes(index)) {
      setOpenDropdowns(openDropdowns.filter((item) => item !== index));
    } else {
      setOpenDropdowns([...openDropdowns, index]);
    }
  };
  const toggleDropdown1 = (index: number) => {
    if (activeDropdown1 === index) {
      setActiveDropdown1(null);
    } else {
      setActiveDropdown1(index);
    }
    if (openDropdowns1.includes(index)) {
      setOpenDropdowns1(openDropdowns1.filter((item) => item !== index));
    } else {
      setOpenDropdowns1([...openDropdowns1, index]);
    }
  };

  document.addEventListener("DOMContentLoaded", function () {
    const dropdownTrigger = document.querySelector(".group");

    if (dropdownTrigger) {
      dropdownTrigger.addEventListener("click", () => {
        dropdownTrigger.classList.toggle("active");
      });
    }
  });

  return (
    <Popover className="relative top-0 w:full">
      <div
        className="absolute inset-0 shadow pointer-events-none"
        aria-hidden="true"
      />
      <Toaster position="top-right" reverseOrder={false} />

      <div className="fixed top-0 z-20 bg-black w-full">
        <div className="mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
          <div className="z-30">
            <Link to="/" className="absolute">
              <img
                className="h-28 -mt-6"
                alt="boatrain"
                src="../../../images/Logos/Boatrain logo new-svg.png"
              />
            </Link>
          </div>


          <div className="flex justify-between items-center md:hidden">
          <div className=" mr-2 md:hidden">

       
              <button
                onClick={openModal}
                type="button"
                className="w-full bg-main rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm"
              >
                Inquire Now
              </button>
            </div>
 

          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button
              onClick={() => setIsMobileMenuOpen(true)}
              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            >
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          </div>
          <div className="container hidden md:flex-1 md:flex md:items-center md:justify-between">
            <div className=""></div>
            <Link
              to="/"
              className="group bg-black text-white rounded-md inline-flex items-center text-base px-3 font-medium hover:text-secondary focus:outline-none  focus:ring-secondary"
            >
              <span>Home</span>
            </Link>
            <div
              onMouseEnter={handlePopoverBoatOpen}
              onMouseLeave={handlePopoverBoatClose}
            >
              <Popover className="relative">
                <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                  <span className="group bg-black text-white rounded-md inline-flex items-center text-base px-3 font-medium hover:text-secondary focus:outline-none  focus:ring-secondary">
                    Boats
                  </span>
                  <ChevronDownIcon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  show={popoverOpen}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                    <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-black text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                      <div className="p-4">
                        <h3 className="px-4 text-sm font-medium leading-6 text-gray-500">
                          Battheli 38
                        </h3>
                        {solutions.map((item) => (
                          <div
                            key={item.name}
                            className="group  relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50"
                          >
                            <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-black">
                              <item.icon
                                className="h-6 w-6 text-gray-600 group-hover:text-main"
                                aria-hidden="true"
                              />
                            </div>
                            <div>
                              <Link
                                to={item.href}
                                className="font-semibold text-black hover:text-main"
                              >
                                {item.name}
                                <span className="absolute inset-0" />
                              </Link>
                              <p className="mt-1 text-gray-600">
                                {item.description}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                      {/* <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                      {callsToAction.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                        >
                          <item.icon
                            className="h-5 w-5 flex-none text-gray-400"
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      ))}
                    </div> */}
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>
            </div>
            <div
              onMouseEnter={handlePopoverDesignOpen}
              onMouseLeave={handlePopoverDesignClose}
            >
              <Popover.Group as="nav" className="flex">
                <Popover>
                  {({ open, close }) => (
                    <>
                      <Popover.Button
                        className={classNames(
                          open ? "text-secondary" : "text-black",
                          "group bg-black text-white rounded-md inline-flex items-center text-base font-medium hover:white "
                        )}
                      >
                        <span>Our Designs</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? "text-secondary" : "text-gray-400",
                            "ml-2 h-5 w-5 group-hover:text-gray-500"
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        show={isPopoverDesignOpen}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 -translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 -translate-y-1"
                      >
                        <Popover.Panel className="hidden md:block absolute z-10 top-full inset-x-0 transform shadow-lg bg-white">
                          <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-6 py-10 lg:px-8">
                            <div className="grid grid-cols-4 gap-x-6 sm:gap-x-8">
                              {/* <div>
                              <h3 className="text-sm font-medium leading-6 text-gray-500">
                                First Response
                              </h3>
                              <div className="mt-6 flow-root">
                                <div className="-my-2">
                                  {firstResponse.map((item) => (
                                    <Link
                                      key={item.name}
                                      to={item.to}
                                      className="flex gap-x-4 py-2 text-sm font-semibold leading-6 text-gray-900"
                                    >
                                      <item.icon
                                        className="h-6 w-6 flex-none text-gray-400"
                                        aria-hidden="true"
                                      />
                                      {item.name}
                                    </Link>
                                  ))}
                                </div>
                              </div>
                            </div> */}
                              <div>
                                <h3 className="text-sm font-medium leading-6 text-gray-500 capitalize">
                                  PASSENGER CRAFTS
                                </h3>
                                <div className="mt-6 flow-root">
                                  <div className="-my-2">
                                    {passenger.map((item) => (
                                      <Link
                                        key={item.name}
                                        to={item.to}
                                        onClick={() =>
                                          setIsPopoverDesignOpen(false)
                                        }
                                        className="flex gap-x-4 py-2 text-sm font-semibold leading-6 text-gray-900"
                                      >
                                        <item.icon
                                          className="h-6 w-6 flex-none text-gray-400"
                                          aria-hidden="true"
                                        />
                                        {item.name}
                                      </Link>
                                      // <div
                                      //   key={item.name}
                                      //   className="py-2 text-sm font-semibold leading-6 text-gray-900"
                                      // >
                                      //   <div
                                      //     className="flex items-center gap-x-4 cursor-pointer"
                                      //     onClick={() => toggleDropdown(index)}
                                      //   >
                                      //     <UsersIcon
                                      //       className="h-6 w-6 flex-none text-gray-400"
                                      //       aria-hidden="true"
                                      //     />
                                      //     <span>{item.name}</span>
                                      //     {openDropdowns.includes(index) ? (
                                      //       <ChevronUpIcon className="h-5 w-5 text-gray-400" />
                                      //     ) : (
                                      //       <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                                      //     )}
                                      //   </div>
                                      //   {activeDropdown === index && (
                                      //     <div className="ml-8 mt-2">
                                      //       {item.children.map((child) => (
                                      //         <div
                                      //           key={child.name}
                                      //           className="py-2 text-gray-700 hover:text-gray-900"
                                      //         >
                                      //           <Link to={child.to}>
                                      //             {child.name}
                                      //           </Link>
                                      //         </div>
                                      //       ))}
                                      //     </div>
                                      //   )}
                                      // </div>
                                    ))}
                                  </div>
                                </div>
                              </div>

                              <div>
                                <h3 className="text-sm font-medium leading-6 text-gray-500">
                                  Fishing & Excursion
                                </h3>
                                <div className="mt-6 flow-root">
                                  <div className="-my-2">
                                    {fishingExcursion.map((item) => (
                                      <Link
                                        key={item.name}
                                        onClick={() =>
                                          setIsPopoverDesignOpen(false)
                                        }
                                        to={item.to}
                                        className="flex gap-x-4 py-2 text-sm font-semibold leading-6 text-gray-900"
                                      >
                                        <item.icon
                                          className="h-6 w-6 flex-none text-gray-400"
                                          aria-hidden="true"
                                        />
                                        {item.name}
                                      </Link>
                                    ))}
                                  </div>
                                </div>
                              </div>

                              <div>
                                <h3 className="text-sm font-medium leading-6 text-gray-500">
                                  Security
                                </h3>
                                <div className="mt-6 flow-root">
                                  <div className="-my-2">
                                    {security.map((item, index) => (
                                      <Link
                                        key={item.name}
                                        onClick={() =>
                                          setIsPopoverDesignOpen(false)
                                        }
                                        to={item.to}
                                        className="flex gap-x-4 py-2 text-sm font-semibold leading-6 text-gray-900"
                                      >
                                        <item.icon
                                          className="h-6 w-6 flex-none text-gray-400"
                                          aria-hidden="true"
                                        />
                                        {item.name}
                                      </Link>
                                    ))}
                                  </div>
                                </div>
                              </div>

                              <div>
                                <h3 className="text-sm font-medium leading-6 text-gray-500">
                                  Cargo
                                </h3>
                                <div className="mt-6 flow-root">
                                  <div className="-my-2">
                                    {cargo.map((item) => (
                                      <Link
                                        key={item.name}
                                        onClick={() =>
                                          setIsPopoverDesignOpen(false)
                                        }
                                        to={item.to}
                                        className="flex gap-x-4 py-2 text-sm font-semibold leading-6 text-gray-900"
                                      >
                                        <item.icon
                                          className="h-6 w-6 flex-none text-gray-400"
                                          aria-hidden="true"
                                        />
                                        {item.name}
                                      </Link>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </Popover.Group>
            </div>

            {/* 
            <Link
              to="/shop"
              className="group bg-white rounded-md inline-flex items-center text-base px-3 font-medium hover:text-secondary focus:outline-none  focus:ring-secondary"
            >
              <span>Shop</span>
            </Link>
            <Link
              to="/blog"
              className="group bg-white rounded-md inline-flex items-center text-base px-3 font-medium hover:text-secondary focus:outline-none  focus:ring-secondary"
            >
              <span>Blog</span>
            </Link> */}
            <div
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              <Popover className="relative">
                <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                  <span className="group bg-black text-white rounded-md inline-flex items-center text-base px-3 font-medium hover:text-secondary focus:outline-none  focus:ring-secondary">
                    Engine
                  </span>
                  <ChevronDownIcon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  show={isPopoverOpen}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                    <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-black text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                      <div className="p-4">
                        {engine.map((item) => (
                          <div
                            key={item.name}
                            className="group  relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50"
                          >
                            <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-black">
                              <item.icon
                                className="h-6 w-6 text-gray-600 group-hover:text-main"
                                aria-hidden="true"
                              />
                            </div>
                            <div>
                              <Link
                                to={item.href}
                                className="font-semibold text-black hover:text-main"
                              >
                                {item.name}
                                <span className="absolute inset-0" />
                              </Link>
                              <p className="mt-1 text-gray-600">
                                {item.description}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                      {/* <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                      {callsToAction.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                        >
                          <item.icon
                            className="h-5 w-5 flex-none text-gray-400"
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      ))}
                    </div> */}
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>
            </div>
            <Link
              to="/blog"
              className="group bg-black text-white rounded-md inline-flex items-center text-base px-3 font-medium hover:text-secondary focus:outline-none  focus:ring-secondary"
            >
              <span>Blog</span>
            </Link>
            <Link
              to="/about"
              className="group bg-black text-white rounded-md inline-flex items-center text-base px-3 font-medium hover:text-secondary focus:outline-none  focus:ring-secondary"
            >
              <span>About us</span>
            </Link>
          </div>

          <div className="hidden mt-6 md:flex flex-col md:flex-row md:max-w-md lg:mt-0">


            <div className="mt-4 md:mt-0">
              <button
                onClick={openModal}
                type="button"
                className="w-full bg-main rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm"
              >
                Inquire Now
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen === true && (
        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="fixed z-30 top-0 inset-x-0  transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="sm:pb-8 space-y-4">
                <div className="flex items-center justify-between bg-black">
                  <div>
                    <Link to="/" className="flex">
                      <img
                        alt="logo"
                        className="h-28 px-2 mt-2"
                        src="../../../images/Logos/Boatrain logo new-svg.png"
                      />
                    </Link>
                  </div>

                  <div className="-mr-2 -mt-8 px-6">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6 sm:mt-8 space-y-4 px-5">
                  <Link onClick={() => setIsMobileMenuOpen(false)} to="/">
                    Home
                  </Link>
                  <Disclosure>
                    {({ open, close }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between rounded-lg  text-left text-sm font-medium focus:outline-none">
                          <span>Boats</span>
                          <ChevronUpIcon
                            className={`${
                              open ? "rotate-180 transform" : ""
                            } h-5 w-5`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                          <div className="bg-white">
                            <div className="">
                              <h3 className="text-sm font-medium leading-6 text-gray-500">
                                Battheli 38
                              </h3>
                              {solutions.map((item) => (
                                <div
                                  key={item.name}
                                  className="group relative flex rounded-lg py-2 hover:bg-gray-50"
                                >
                                  <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                    <item.icon
                                      className="h-6 w-6 text-gray-600 group-hover:text-blue-600"
                                      aria-hidden="true"
                                    />
                                  </div>
                                  <div>
                                    <Link
                                      to={item.href}
                                      onClick={() => setIsMobileMenuOpen(false)}
                                      className="font-semibold text-gray-900"
                                    >
                                      {item.name}
                                      <span className="absolute inset-0" />
                                    </Link>
                                    <p className="mt-1 text-gray-600">
                                      {item.description}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  <Disclosure>
                    {({ open, close }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between rounded-lg  text-left text-sm font-medium focus:outline-none">
                          <span>Our Designs</span>
                          <ChevronUpIcon
                            className={`${
                              open ? "rotate-180 transform" : ""
                            } h-5 w-5`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="pt-4 text-sm text-gray-500">
                          <div className="mx-auto grid grid-cols-1">
                            <div className="grid grid-cols-1 sm:gap-x-8 px-4">
                              {/* <div>
                              <h3 className="text-sm font-medium leading-6 text-gray-500">
                                First Response
                              </h3>
                              <div className="mt-6 flow-root">
                                <div className="-my-2">
                                  {firstResponse.map((item) => (
                                    <Link
                                      key={item.name}
                                      to={item.to}
                                      className="flex gap-x-4 py-2 text-sm font-semibold leading-6 text-gray-900"
                                    >
                                      <item.icon
                                        className="h-6 w-6 flex-none text-gray-400"
                                        aria-hidden="true"
                                      />
                                      {item.name}
                                    </Link>
                                  ))}
                                </div>
                              </div>
                            </div> */}

                              <div>
                                <h3 className="text-sm font-medium leading-6 text-gray-500">
                                  Cargo
                                </h3>
                                <div className="mt-6 flow-root">
                                  <div className="-my-2">
                                    {cargo.map((item) => (
                                      <Link
                                        key={item.name}
                                        to={item.to}
                                        onClick={() =>
                                          setIsMobileMenuOpen(false)
                                        }
                                        className="flex gap-x-4 py-2 text-sm font-semibold leading-6 text-gray-900"
                                      >
                                        <item.icon
                                          className="h-6 w-6 flex-none text-gray-400"
                                          aria-hidden="true"
                                        />
                                        {item.name}
                                      </Link>
                                    ))}
                                  </div>
                                </div>
                              </div>

                              <div>
                                <h3 className="text-sm font-medium leading-6 text-gray-500">
                                  Fishing & Excursion
                                </h3>
                                <div className="mt-6 flow-root">
                                  <div className="-my-2">
                                    {fishingExcursion.map((item) => (
                                      <Link
                                        key={item.name}
                                        to={item.to}
                                        onClick={() =>
                                          setIsMobileMenuOpen(false)
                                        }
                                        className="flex gap-x-4 py-2 text-sm font-semibold leading-6 text-gray-900"
                                      >
                                        <item.icon
                                          className="h-6 w-6 flex-none text-gray-400"
                                          aria-hidden="true"
                                        />
                                        {item.name}
                                      </Link>
                                    ))}
                                  </div>
                                </div>
                              </div>
                              <div>
                                <h3 className="text-sm font-medium leading-6 text-gray-500 capitalize">
                                  PASSENGER CRAFTS
                                </h3>
                                <div className="mt-6 flow-root">
                                  <div className="-my-2">
                                    {passenger.map((item, index) => (
                                      <Link
                                        key={item.name}
                                        to={item.to}
                                        onClick={() =>
                                          setIsMobileMenuOpen(false)
                                        }
                                        className="flex gap-x-4 py-2 text-sm font-semibold leading-6 text-gray-900"
                                      >
                                        <item.icon
                                          className="h-6 w-6 flex-none text-gray-400"
                                          aria-hidden="true"
                                        />
                                        {item.name}
                                      </Link>
                                    ))}
                                  </div>
                                </div>
                              </div>
                              <div>
                                <h3 className="text-sm font-medium leading-6 text-gray-500">
                                  Security
                                </h3>
                                <div className="mt-6 flow-root">
                                  <div className="-my-2">
                                    {security.map((item, index) => (
                                      <Link
                                        key={item.name}
                                        to={item.to}
                                        onClick={() =>
                                          setIsMobileMenuOpen(false)
                                        }
                                        className="flex gap-x-4 py-2 text-sm font-semibold leading-6 text-gray-900"
                                      >
                                        <item.icon
                                          className="h-6 w-6 flex-none text-gray-400"
                                          aria-hidden="true"
                                        />
                                        {item.name}
                                      </Link>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between rounded-lg  text-left text-sm font-medium focus:outline-none">
                          <span>Engine</span>
                          <ChevronUpIcon
                            className={`${
                              open ? "rotate-180 transform" : ""
                            } h-5 w-5 text-white`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                          <div className="bg-white">
                            <div className="">
                              <h3 className="text-sm font-medium leading-6 text-gray-500"></h3>
                              {engine.map((item) => (
                                <div
                                  key={item.name}
                                  onClick={() => setIsMobileMenuOpen(false)}
                                  className="group relative flex rounded-lg py-2 hover:bg-gray-50"
                                >
                                  <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                    <item.icon
                                      className="h-6 w-6 text-gray-600 group-hover:text-blue-600"
                                      aria-hidden="true"
                                    />
                                  </div>
                                  <div>
                                    <Link
                                      to={item.href}
                                      className="font-semibold text-gray-900"
                                    >
                                      {item.name}
                                      <span className="absolute inset-0" />
                                    </Link>
                                    <p className="mt-1 text-gray-600">
                                      {item.description}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>

                {/* <Link className="flex" to="/shop">
                Shop
              </Link>
              <Link className="flex" to="/blog">
                Blog
              </Link> */}
                <Link
                  onClick={() => setIsMobileMenuOpen(false)}
                  className="flex px-5"
                  to="/blog"
                >
                  Blog
                </Link>
                <Link
                  onClick={() => setIsMobileMenuOpen(false)}
                  className="flex px-5"
                  to="/about"
                >
                  About us
                </Link>
              </div>

              <div className="py-6 px-5"></div>
            </div>
          </Popover.Panel>
        </Transition>
      )}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-white bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-scuba"
                  >
                    Inquiry
                  </Dialog.Title>
                  <div className="mt-2">
                    <form
                      onSubmit={handleSubmit}
                      className="mt-6 grid grid-cols-1 gap-y-2 sm:grid-cols-1 sm:gap-x-2"
                    >
                      <div className="col-span-2">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-500"
                        >
                          Email
                        </label>
                        <div className="mt-1">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            value={formData.email}
                            onChange={handleChange}
                            className="block  w-full rounded-md border-gray-300 py-3 px-4 text-gray-500 shadow-sm focus:ring-teal-600 focus:border-teal-600 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div className="col-span-2">
                        <div className="flex justify-between">
                          <label
                            htmlFor="phone"
                            className="block text-sm font-medium text-gray-500"
                          >
                            Phone
                          </label>
                        </div>
                        <div className="mt-1">
                          <input
                            type="tel"
                            name="phone"
                            id="phone"
                            autoComplete="tel"
                            required
                            value={formData.phone}
                            onChange={handleChange}
                            className="block w-full  rounded-md border-gray-300 py-3 px-4 text-gray-500 shadow-sm focus:ring-teal-600 focus:border-teal-600 sm:text-sm "
                          />
                        </div>
                      </div>
                      <div className="col-span-2">
                        <div className="flex justify-between">
                          <label
                            htmlFor="message"
                            className="block text-sm font-medium text-gray-500"
                          >
                            Message
                          </label>
                          <span
                            id="message-max"
                            className="text-sm  text-gray-500"
                          >
                            Max. 500 characters
                          </span>
                        </div>
                        <div className="mt-1">
                          <textarea
                            id="message"
                            name="message"
                            rows={4}
                            required
                            value={formData.message}
                            onChange={handleChange}
                            className="block w-full  rounded-md border-gray-300 py-3 px-4 text-gray-500 shadow-sm focus:ring-teal-600 focus:border-teal-600 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2 sm:flex sm:justify-end">
                        <div className="mt-4">
                          <button
                            onClick={() => setIsOpen(false)}
                            type="button"
                            className="mt-2  inline-flex w-full items-center justify-center rounded-md border border-transparent bg-scuba px-6 py-3 text-base font-medium  shadow-sm border-teal-600 text-teal-600 focus:outline-none focus:ring-2 focus:ring-scuba focus:ring-offset-2 sm:w-auto"
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="mt-2 ml-2 text-white  inline-flex w-full items-center justify-center rounded-md border border-transparent bg-scuba px-6 py-3 text-base font-medium shadow-sm bg-teal-600 focus:outline-none focus:ring-2 focus:ring-scuba focus:ring-offset-2 sm:w-auto"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Popover>
  );
}
