export default function Navbar() {
  return (
    <>
      <section className="h-screen relative">
        <img
          src="../../../images/HomePage/CoverImage_1.jpg"
          className="absolute inset-0 w-full h-full object-cover"
          alt="Background"
        />
        <div className="container z-2 mx-auto relative p-2 py-28 md:py-46">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-8 md:col-span-7 md:mb-16">
              <div className="md:mr-6">
                <div
                  className="font-bold text-white lg:leading-normal leading-normal text-2xl lg:text-5xl mb-2"
                  style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)" }}
                >
                  SPECIALIZING IN BOAT BUILDING <br />
                  PREMIUM MARINE PRODCUTS <br />
                  AND REPAIR SERVICES{" "}
                </div>
                <p
                  className="text-white text-md max-w-xl"
                  style={{ textShadow: "1px 1px 2px rgba(0, 0, 0, 0.6)" }}
                >
                  Boatrain Pvt Ltd
                </p>
              </div>
            </div>
            {/* <!--end col--> */}
          </div>
          {/* <!--end grid--> */}
        </div>
        {/* <!--end container--> */}
      </section>
    </>
  );
}
