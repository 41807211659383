import React from "react";
import Section from "../../../components/Boat/Section";
import General from "../sections/general";

const Logistics = () => {
  return (
    <>
      {/* <Info /> */}
      <div className="lg:mx-auto lg:grid-cols-1 mt-12">
        <div className="flex container mx-auto">
          <Section id="section1" label="">
            <General
              title="KF38 Logistics"
              content="The KF-38 Logistics Support Boat (LSB) is a versatile vessel that is designed to meet the unique challenges of transporting goods and supplies. It is also equipped to perform as a support vessel. With a large and spacious deck, the vessel can accommodate light cargo and equipment while ensuring maximum safety and security for the crew."
              imageSrc="https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty-300x240.jpg"
            />
          </Section>
        </div>
      </div>
    </>
  );
};

export default Logistics;
