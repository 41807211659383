import { Dialog, Transition } from "@headlessui/react";
import React, { FC, Fragment, useState } from "react";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";

const navigation = {
  social: [
    {
      name: "Facebook",
      href: "https://facebook.com/boatrainmv",
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/boatrainmv",
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Twitter",
      href: "https://twitter.com/boatrainmv",
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },

    {
      name: "YouTube",
      href: "https://www.youtube.com/channel/UC5R4_sJslI1hB--mnR6GVZw?app=desktop",
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Linkedin",
      href: "https://www.linkedin.com/in/boatrainmv",
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
        </svg>
      ),
    },
  ],
};

const Footer = ({}) => {
  const location = useLocation();
  let [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    message: "",
  });
  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const apiUrl = "https://api.boatrain.com/api/send-email";
    const currentUrl = location.pathname;

    const data = {
      email: formData.email,
      message: formData.message,
      phone: formData.phone,
      link: currentUrl,
    };

    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(apiUrl, data, { headers })
      .then((response) => {
        toast.success("Email sent successfully!");
        setIsOpen(false);
      })
      .catch((error) => {
        toast.error("Error sending email. Please try again.");
      });
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  return (
    <footer className="bg-black" aria-labelledby="footer-heading">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="mx-auto max-w-full px-6 pb-8 pt-12">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="mt-16 flex flex-col gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:gap-1">
              <div className="flex space-x-4 justify-center md:justify-start md:space-x-6 md:order-2">
                {navigation.social.map((item: any) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">{item.name}</span>
                    <item.icon
                      className="h-12 w-12 md:h-16 md:w-16"
                      aria-hidden="true"
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-12 xl:mt-0">
            <h3 className="text-sm font-semibold leading-6 text-white">
              Inquire to us now
            </h3>
            <p className="mt-2 text-sm leading-6 text-gray-300">
              Sent to your details to our inbox.
            </p>
            <div className="mt-6 flex flex-col md:flex-row md:max-w-md lg:mt-0">
              <div className="mt-4 md:mt-0">
                <button
                  onClick={openModal}
                  type="button"
                  className="w-full bg-main rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm"
                >
                  Contact us
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2 border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex justify-center md:justify-start md:space-x-6 md:order-2"></div>
          <p className="text-center md:text-left text-xs leading-5 text-white">
            &copy; 2023 Boatrain, Inc. All rights reserved.
          </p>
        </div>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-white bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-scuba"
                  >
                    Inquiry
                  </Dialog.Title>
                  <div className="mt-2">
                    <form
                      onSubmit={handleSubmit}
                      className="mt-6 grid grid-cols-1 gap-y-2 sm:grid-cols-1 sm:gap-x-2"
                    >
                      <div className="col-span-2">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-500"
                        >
                          Email
                        </label>
                        <div className="mt-1">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            value={formData.email}
                            onChange={handleChange}
                            className="block  w-full rounded-md border-gray-300 py-3 px-4 text-gray-500 shadow-sm focus:ring-teal-600 focus:border-teal-600 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div className="col-span-2">
                        <div className="flex justify-between">
                          <label
                            htmlFor="phone"
                            className="block text-sm font-medium text-gray-500"
                          >
                            Phone
                          </label>
                        </div>
                        <div className="mt-1">
                          <input
                            type="tel"
                            name="phone"
                            id="phone"
                            autoComplete="tel"
                            required
                            value={formData.phone}
                            onChange={handleChange}
                            className="block w-full  rounded-md border-gray-300 py-3 px-4 text-gray-500 shadow-sm focus:ring-teal-600 focus:border-teal-600 sm:text-sm "
                          />
                        </div>
                      </div>
                      <div className="col-span-2">
                        <div className="flex justify-between">
                          <label
                            htmlFor="message"
                            className="block text-sm font-medium text-gray-500"
                          >
                            Message
                          </label>
                          <span
                            id="message-max"
                            className="text-sm  text-gray-500"
                          >
                            Max. 500 characters
                          </span>
                        </div>
                        <div className="mt-1">
                          <textarea
                            id="message"
                            name="message"
                            rows={4}
                            required
                            value={formData.message}
                            onChange={handleChange}
                            className="block w-full  rounded-md border-gray-300 py-3 px-4 text-gray-500 shadow-sm focus:ring-teal-600 focus:border-teal-600 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2 sm:flex sm:justify-end">
                        <div className="mt-4">
                          <button
                            onClick={() => setIsOpen(false)}
                            type="button"
                            className="mt-2  inline-flex w-full items-center justify-center rounded-md border border-transparent bg-scuba px-6 py-3 text-base font-medium  shadow-sm border-teal-600 text-teal-600 focus:outline-none focus:ring-2 focus:ring-scuba focus:ring-offset-2 sm:w-auto"
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="mt-2 ml-2 text-white  inline-flex w-full items-center justify-center rounded-md border border-transparent bg-scuba px-6 py-3 text-base font-medium shadow-sm bg-teal-600 focus:outline-none focus:ring-2 focus:ring-scuba focus:ring-offset-2 sm:w-auto"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </footer>
  );
};

export default Footer;
