import React from "react";
import GeneralDesign from "../sections/generalDesign";

const TroopCarrier = () => {
  return (
    <>
      {/* <Info /> */}
      <div className="lg:mx-auto lg:grid-cols-1 mt-12">
        <div className="flex">
        <GeneralDesign
               asluTitle="KF38 TROOP CARRIER"
              title="KF38 TROOP CARRIER"
              content="Our KF-38 Troop Carrier is a meticulously designed vessel that prioritizes both efficiency and safety in the transportation of troops to any operation. The vessel boasts a space-efficient and versatile design, allowing for the smooth and seamless deployment of up to 30 troops, in addition to the crew."
              imageSrc="../../../../images/Boat pages/KF38 Troop Carrier/1.png"
              imageSrc2="../../../../images/Boat pages/KF38 Troop Carrier/2.png"
              imageSrc3="../../../../images/Boat pages/KF38 Troop Carrier/3.png"
              imageSrc4="../../../../images/Boat pages/KF38 Troop Carrier/4.png"
              imageSrc5="../../../../images/Boat pages/KF38 Troop Carrier/5.png"
              imageSrc6="../../../../images/Boat pages/KF38 Troop Carrier/6.png"
              imageSrc7="../../../../images/Boat pages/KF38 Troop Carrier/7.png"
              imageSrc8="../../../../images/Boat pages/KF38 Troop Carrier/8.png"
              imageSrc9="../../../../images/Boat pages/KF38 Troop Carrier 2/1.png"
              imageSrc10="../../../../images/Boat pages/KF38 Troop Carrier 2/2.png"
              imageSrc11="../../../../images/Boat pages/KF38 Troop Carrier 2/3.png"
              imageSrc12="../../../../images/Boat pages/KF38 Troop Carrier 2/4.png"
              imageSrc13="../../../../images/Boat pages/KF38 Troop Carrier 2/5.png"
              imageSrc14="../../../../images/Boat pages/KF38 Troop Carrier 2/6.png"
              imageSrc15="../../../../images/Boat pages/KF38 Troop Carrier 2/7.png"
              imageSrc16="../../../../images/Boat pages/KF38 Troop Carrier 2/8.png"
  
            />
      
        </div>
      </div>
    </>
  );
};

export default TroopCarrier;
