import React from "react";
import GeneralDesign from "../sections/generalDesign";
const K65Cargo = () => {
  return (
    <>
      <div className="lg:mx-auto lg:grid-cols-1 mt-12">
        <div className="flex">
          <GeneralDesign
            asluTitle="aslu title here"
            describe="descbie bessel"
            title="KF38 Habour Patrol"
            content="Introducing the Kalhuoh Fummi 38 Harbour Patrol Craft, the ultimate solution for
              safeguarding and patrolling the inshore waters and harbours. This vessel is a highly
              versatile and manoeuvrable craft, designed to provide maximum comfort and protection
              to all personnel on board, while also delivering exceptional performance and efficiency.
              Equipped with the latest navigation and communication systems, the crew can stay
              informed and always connected, ensuring optimal safety and efficiency."
            imageSrc="../../../../images/Boat pages/K65' Cargo/1.png"
            imageSrc2="../../../../images/Boat pages/K65' Cargo/2.png"
            imageSrc3="../../../../images/Boat pages/K65' Cargo/3.png"
            imageSrc4="../../../../images/Boat pages/K65' Cargo/4.png"
          />
        </div>
      </div>
    </>
  );
};

export default K65Cargo;
