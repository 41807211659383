import React from "react";
import GeneralDesign from "../sections/generalDesign";
const K99Cargo = () => {
  return (
    <>
      <div className="lg:mx-auto lg:grid-cols-1 mt-12">
        <div className="flex">
          <GeneralDesign
            asluTitle="99' Cargo"
            describe="Your Cargo, Our Commitment"
            title="Logistics Perfected"
            content="Our 99' cargo ship represents the pinnacle of maritime cargo transport. Built for reliability and efiiciency, it's a testament to our dedication to the world of trade and logistics. With a robust design and state-of-the-art cargo handling capabilities, this vessel ensures that your goods reach their destination with unmatched safety and precision. Discover the cargo ship that's set to redefine the future of maritime commerce."
            imageSrc="../../../../images/Boat pages/99' Cargo/1.jpg"
            imageSrc2="../../../../images/Boat pages/99' Cargo/2.jpg"
            imageSrc3="../../../../images/Boat pages/99' Cargo/3.jpg"
            imageSrc4="../../../../images/Boat pages/99' Cargo/4.jpg"
            imageSrc5="../../../../images/Boat pages/99' Cargo/5.jpg"
            imageSrc6="../../../../images/Boat pages/99' Cargo/6.jpg"
            imageSrc7="../../../../images/Boat pages/99' Cargo/7.jpg"
            imageSrc8="../../../../images/Boat pages/99' Cargo/8.jpg"
            imageSrc9="../../../../images/Boat pages/99' Cargo/9.jpg"
            imageSrc10="../../../../images/Boat pages/99' Cargo/10.jpg"
            imageSrc11="../../../../images/Boat pages/99' Cargo/11.jpg"
            imageSrc12="../../../../images/Boat pages/99' Cargo/12.jpg"
            imageSrc13="../../../../images/Boat pages/99' Cargo/13.jpg"
            imageSrc14="../../../../images/Boat pages/99' Cargo/14.jpg"
            imageSrc15="../../../../images/Boat pages/99' Cargo/15.jpg"
            imageSrc16="../../../../images/Boat pages/99' Cargo/16.jpg"
            imageSrc17="../../../../images/Boat pages/99' Cargo/17.jpg"
            imageSrc18="../../../../images/Boat pages/99' Cargo/18.jpg"
            imageSrc19="../../../../images/Boat pages/99' Cargo/19.jpg"
            imageSrc20="../../../../images/Boat pages/99' Cargo/20.jpg"
            imageSrc21="../../../../images/Boat pages/99' Cargo/21.jpg"
            imageSrc22="../../../../images/Boat pages/99' Cargo/22.jpg"
            imageSrc23="../../../../images/Boat pages/99' Cargo/23.jpg"
            imageSrc24="../../../../images/Boat pages/99' Cargo/24.jpg"
          />
        </div>
      </div>
    </>
  );
};

export default K99Cargo;

