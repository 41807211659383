import React from "react";
import Section from "../../../components/Boat/Section";
import General from "../sections/general";

const SeaAmublance = () => {
  return (
    <>
      {/* <Info /> */}
      <div className="lg:mx-auto lg:grid-cols-1 mt-12">
        <div className="flex container mx-auto">
          <Section id="section1" label="">
            <General
              title="KF38 SEA AMBULANCE"
              content="The Sea Ambulance model is designed to meet specific needs of medical transport in the Maldives and is made compatible to accommodate/install required equipment for medical emergencies. This provides a flexible solution ensuring that patients receive the care they need, no matter where they are in the Maldives."
              imageSrc="https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty-300x240.jpg"
            />
          </Section>
        </div>
      </div>
    </>
  );
};

export default SeaAmublance;
