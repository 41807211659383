import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Boat from "../../components/Boat/Boat";
import MegaMenu from "../../components/Navbar/Mega";

export default function About() {
  const clipPathPolygon =
    "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)";

  const divStyles = {
    clipPath: clipPathPolygon,
    backgroundColor: "linear-gradient(to right, #80caff, #4f46e5)",
    opacity: 0.25,
    width: "69.25rem",
    aspectRatio: "1108/632",
    flex: "none",
  };

  const clipPathPolygon1 =
    "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)";

  const divStyles1 = {
    clipPath: clipPathPolygon,
    backgroundColor: "linear-gradient(to right, #80caff, #4f46e5)",
    opacity: 0.25,
    width: "82.375rem",
    aspectRatio: "1318/752",
    flex: "none",
  };

  return (
    <div className="">
      <div className="bg-main">
        <main className="relative isolate">
          <div
            className="absolute inset-x-0 top-4 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
            aria-hidden="true"
          >
            <div
              className="bg-gradient-to-r from-[#045f2a] to-[#0b1b12] opacity-25"
              style={divStyles}
            ></div>
          </div>

          <div className="px-6 pt-14 lg:px-8">
            <div className="mx-auto max-w-2xl pt-24 text-center sm:pt-40">
              <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                Boatrain Introduction
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-800">
                Boatrain Pvt Ltd is a Maldivian-owned and operated company,
                working in collaboration with Fix in Marine Pvt Ltd and Diesel
                Outboard Company Pvt Ltd (D.O.C). Specialized in advanced
                design, and manufacturing, and after-sales services of
                Glass-Reinforced Plastic (GRP) vessels. We also provide sales &
                after-sales support for COX Diesel Outboard engines.
              </p>
            </div>
          </div>

          <div className="mx-auto mt-20 max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <div className="grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-800 lg:max-w-none lg:grid-cols-2">
                <div>
                  <p>
                    We focus on delivering the best for our customers whether it
                    be a speedboat or a fiberglass Dhoani. Our philosophy
                    centers around the utilization of light-weight materials to
                    achieve heightened velocity, energy efficiency, and overall
                    performance improvement. Building a light-weight boat
                    involves a comprehensive approach, ensuring that every
                    component is meticulously designed to reduce weight. Our
                    technique involves constructing an actual size prototype
                    model with the final product in mind, resulting in a
                    lightweight yet robust boat. Rather than subtracting
                    elements, our approach focuses on adding to achieve a
                    light-weight structure.
                  </p>
                  <p className="mt-8">
                    Crafting the ultimate high-speed boat extends beyond a
                    powerful engine. We carefully consider various factors, such
                    as hull and deck design, interior layout, and decor,
                    tailoring them to the preferences of each owner. These
                    elements significantly impact the boat's weight and balance.
                    During the selection and installation of the engine, we
                    meticulously assess all aspects, including the configuration
                    of drives such as shafts, transmission, and propellers. This
                    meticulous attention guarantees the assembly of an
                    unparalleled high-speed boat with exceptional performance
                    and energy efficiency.
                  </p>
                </div>
                <div>
                  <p>
                    Since our inception, we have taken immense pride in
                    upholding excellent standards, ensuring the utmost
                    satisfaction of our clients. They are particularly impressed
                    not only by the speed our boats offer but also by their
                    stability in rough waters and the effortless glide
                    experienced during high-speed manoeuvres.
                  </p>
                  <p className="mt-8">
                    Our commitment remains unwavering in the continuous
                    development and manufacturing of superior boats that cater
                    to the present and future needs of our esteemed customers.
                  </p>
                </div>
              </div>
              <dl className="mt-16 grid grid-cols-1 gap-x-8 gap-y-12 sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mt-28 lg:grid-cols-4">
                <div className="flex flex-col-reverse gap-y-3 border-l border-white/20 pl-6">
                  <dt className="text-base leading-7 text-gray-800">
                    Business was founded
                  </dt>
                  <dd className="text-3xl font-semibold tracking-tight text-white">
                    2018
                  </dd>
                </div>
                <div className="flex flex-col-reverse gap-y-3 border-l border-white/20 pl-6">
                  <dt className="text-base leading-7 text-gray-800">
                    People on the team
                  </dt>
                  <dd className="text-3xl font-semibold tracking-tight text-white">
                    70+
                  </dd>
                </div>
                <div className="flex flex-col-reverse gap-y-3 border-l border-white/20 pl-6">
                  <dt className="text-base leading-7 text-gray-800">
                    Repair Services
                  </dt>
                  <dd className="text-3xl font-semibold tracking-tight text-white">
                    300+
                  </dd>
                </div>
                <div className="flex flex-col-reverse gap-y-3 border-l border-white/20 pl-6">
                  <dt className="text-base leading-7 text-gray-800">
                    Boats Built
                  </dt>
                  <dd className="text-3xl font-semibold tracking-tight text-white">
                    4
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          {/* <div className="mt-32 sm:mt-40 xl:mx-auto xl:max-w-7xl xl:px-8">
            <img
              src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2894&q=80"
              alt=""
              className="aspect-[9/4] w-full object-cover xl:rounded-3xl"
            />
          </div> */}

          {/* <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Our values
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-800">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Maiores impedit perferendis suscipit eaque, iste dolor
                cupiditate blanditiis.
              </p>
            </div>
            <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-gray-800 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
              <div className="relative pl-9">
                <dt className="inline font-semibold text-white">
                  <svg
                    className="absolute left-1 top-1 h-5 w-5 text-indigo-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.606 12.97a.75.75 0 01-.134 1.051 2.494 2.494 0 00-.93 2.437 2.494 2.494 0 002.437-.93.75.75 0 111.186.918 3.995 3.995 0 01-4.482 1.332.75.75 0 01-.461-.461 3.994 3.994 0 011.332-4.482.75.75 0 011.052.134z"
                      clip-rule="evenodd"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M5.752 12A13.07 13.07 0 008 14.248v4.002c0 .414.336.75.75.75a5 5 0 004.797-6.414 12.984 12.984 0 005.45-10.848.75.75 0 00-.735-.735 12.984 12.984 0 00-10.849 5.45A5 5 0 001 11.25c.001.414.337.75.751.75h4.002zM13 9a2 2 0 100-4 2 2 0 000 4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Be world-class.
                </dt>
                <dd className="inline">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit aute
                  id magna.
                </dd>
              </div>
              <div className="relative pl-9">
                <dt className="inline font-semibold text-white">
                  <svg
                    className="absolute left-1 top-1 h-5 w-5 text-indigo-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M11 2a1 1 0 10-2 0v6.5a.5.5 0 01-1 0V3a1 1 0 10-2 0v5.5a.5.5 0 01-1 0V5a1 1 0 10-2 0v7a7 7 0 1014 0V8a1 1 0 10-2 0v3.5a.5.5 0 01-1 0V3a1 1 0 10-2 0v5.5a.5.5 0 01-1 0V2z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Take responsibility.
                </dt>
                <dd className="inline">
                  Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure
                  qui lorem cupidatat commodo.
                </dd>
              </div>
              <div className="relative pl-9">
                <dt className="inline font-semibold text-white">
                  <svg
                    className="absolute left-1 top-1 h-5 w-5 text-indigo-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M10 9a3 3 0 100-6 3 3 0 000 6zM6 8a2 2 0 11-4 0 2 2 0 014 0zM1.49 15.326a.78.78 0 01-.358-.442 3 3 0 014.308-3.516 6.484 6.484 0 00-1.905 3.959c-.023.222-.014.442.025.654a4.97 4.97 0 01-2.07-.655zM16.44 15.98a4.97 4.97 0 002.07-.654.78.78 0 00.357-.442 3 3 0 00-4.308-3.517 6.484 6.484 0 011.907 3.96 2.32 2.32 0 01-.026.654zM18 8a2 2 0 11-4 0 2 2 0 014 0zM5.304 16.19a.844.844 0 01-.277-.71 5 5 0 019.947 0 .843.843 0 01-.277.71A6.975 6.975 0 0110 18a6.974 6.974 0 01-4.696-1.81z" />
                  </svg>
                  Be supportive.
                </dt>
                <dd className="inline">
                  Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus
                  voluptas blanditiis et.
                </dd>
              </div>
              <div className="relative pl-9">
                <dt className="inline font-semibold text-white">
                  <svg
                    className="absolute left-1 top-1 h-5 w-5 text-indigo-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9.664 1.319a.75.75 0 01.672 0 41.059 41.059 0 018.198 5.424.75.75 0 01-.254 1.285 31.372 31.372 0 00-7.86 3.83.75.75 0 01-.84 0 31.508 31.508 0 00-2.08-1.287V9.394c0-.244.116-.463.302-.592a35.504 35.504 0 013.305-2.033.75.75 0 00-.714-1.319 37 37 0 00-3.446 2.12A2.216 2.216 0 006 9.393v.38a31.293 31.293 0 00-4.28-1.746.75.75 0 01-.254-1.285 41.059 41.059 0 018.198-5.424zM6 11.459a29.848 29.848 0 00-2.455-1.158 41.029 41.029 0 00-.39 3.114.75.75 0 00.419.74c.528.256 1.046.53 1.554.82-.21.324-.455.63-.739.914a.75.75 0 101.06 1.06c.37-.369.69-.77.96-1.193a26.61 26.61 0 013.095 2.348.75.75 0 00.992 0 26.547 26.547 0 015.93-3.95.75.75 0 00.42-.739 41.053 41.053 0 00-.39-3.114 29.925 29.925 0 00-5.199 2.801 2.25 2.25 0 01-2.514 0c-.41-.275-.826-.541-1.25-.797a6.985 6.985 0 01-1.084 3.45 26.503 26.503 0 00-1.281-.78A5.487 5.487 0 006 12v-.54z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Always learning.
                </dt>
                <dd className="inline">
                  Iure sed ab. Aperiam optio placeat dolor facere. Officiis
                  pariatur eveniet atque et dolor.
                </dd>
              </div>
              <div className="relative pl-9">
                <dt className="inline font-semibold text-white">
                  <svg
                    className="absolute left-1 top-1 h-5 w-5 text-indigo-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M15.98 1.804a1 1 0 00-1.96 0l-.24 1.192a1 1 0 01-.784.785l-1.192.238a1 1 0 000 1.962l1.192.238a1 1 0 01.785.785l.238 1.192a1 1 0 001.962 0l.238-1.192a1 1 0 01.785-.785l1.192-.238a1 1 0 000-1.962l-1.192-.238a1 1 0 01-.785-.785l-.238-1.192zM6.949 5.684a1 1 0 00-1.898 0l-.683 2.051a1 1 0 01-.633.633l-2.051.683a1 1 0 000 1.898l2.051.684a1 1 0 01.633.632l.683 2.051a1 1 0 001.898 0l.683-2.051a1 1 0 01.633-.633l2.051-.683a1 1 0 000-1.898l-2.051-.683a1 1 0 01-.633-.633L6.95 5.684zM13.949 13.684a1 1 0 00-1.898 0l-.184.551a1 1 0 01-.632.633l-.551.183a1 1 0 000 1.898l.551.183a1 1 0 01.633.633l.183.551a1 1 0 001.898 0l.184-.551a1 1 0 01.632-.633l.551-.183a1 1 0 000-1.898l-.551-.184a1 1 0 01-.633-.632l-.183-.551z" />
                  </svg>
                  Share everything you know.
                </dt>
                <dd className="inline">
                  Laudantium tempora sint ut consectetur ratione. Ut illum ut
                  rem numquam fuga delectus.
                </dd>
              </div>
              <div className="relative pl-9">
                <dt className="inline font-semibold text-white">
                  <svg
                    className="absolute left-1 top-1 h-5 w-5 text-indigo-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M10 2a.75.75 0 01.75.75v1.5a.75.75 0 01-1.5 0v-1.5A.75.75 0 0110 2zM10 15a.75.75 0 01.75.75v1.5a.75.75 0 01-1.5 0v-1.5A.75.75 0 0110 15zM10 7a3 3 0 100 6 3 3 0 000-6zM15.657 5.404a.75.75 0 10-1.06-1.06l-1.061 1.06a.75.75 0 001.06 1.06l1.06-1.06zM6.464 14.596a.75.75 0 10-1.06-1.06l-1.06 1.06a.75.75 0 001.06 1.06l1.06-1.06zM18 10a.75.75 0 01-.75.75h-1.5a.75.75 0 010-1.5h1.5A.75.75 0 0118 10zM5 10a.75.75 0 01-.75.75h-1.5a.75.75 0 010-1.5h1.5A.75.75 0 015 10zM14.596 15.657a.75.75 0 001.06-1.06l-1.06-1.061a.75.75 0 10-1.06 1.06l1.06 1.06zM5.404 6.464a.75.75 0 001.06-1.06l-1.06-1.06a.75.75 0 10-1.061 1.06l1.06 1.06z" />
                  </svg>
                  Enjoy downtime.
                </dt>
                <dd className="inline">
                  Culpa dolorem voluptatem velit autem rerum qui et corrupti.
                  Quibusdam quo placeat.
                </dd>
              </div>
            </dl>
          </div> */}

          <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8 py-2">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Our team
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-800">
                Meet our highly skilled marine team, where expertise meets
                professionalism. With years of experience in the marine
                industry, we're your trusted partners for all things maritime.
              </p>
            </div>
            <ul
              role="list"
              className="mx-auto py-1 mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-14 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 xl:grid-cols-4"
            >
              <li>
                {/* <img
                  className="aspect-[14/13] w-full rounded-2xl object-cover"
                  src="https://media.licdn.com/dms/image/C5103AQE3qB-TmOvGkQ/profile-displayphoto-shrink_800_800/0/1547092572430?e=2147483647&v=beta&t=fdYMpfhLWoVUm1CeGXklXzHPkW76hzzPEvxN6qQi5a4"
                  alt=""
                /> */}
                <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-white">
                  Ahmed Ihusaan
                </h3>
                <p className="text-base leading-7 text-gray-800">
                  Managing Director
                </p>
              </li>
              <li>
                {/* <img
                  className="aspect-[14/13] w-full rounded-2xl object-cover"
                  src="https://media.licdn.com/dms/image/C5103AQE3qB-TmOvGkQ/profile-displayphoto-shrink_800_800/0/1547092572430?e=2147483647&v=beta&t=fdYMpfhLWoVUm1CeGXklXzHPkW76hzzPEvxN6qQi5a4"
                  alt=""
                /> */}
                <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-white">
                  Ali Shifau
                </h3>
                <p className="text-base leading-7 text-gray-800">Director</p>
              </li>
              <li>
                {/* <img
                  className="aspect-[14/13] w-full rounded-2xl object-cover"
                  src="https://media.licdn.com/dms/image/C5103AQE3qB-TmOvGkQ/profile-displayphoto-shrink_800_800/0/1547092572430?e=2147483647&v=beta&t=fdYMpfhLWoVUm1CeGXklXzHPkW76hzzPEvxN6qQi5a4"
                  alt=""
                /> */}
                <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-white">
                  Ahmed Hassan
                </h3>
                <p className="text-base leading-7 text-gray-800">Director</p>
              </li>
              <li>
                {/* <img
                  className="aspect-[14/13] w-full rounded-2xl object-cover"
                  src="https://media.licdn.com/dms/image/C5103AQE3qB-TmOvGkQ/profile-displayphoto-shrink_800_800/0/1547092572430?e=2147483647&v=beta&t=fdYMpfhLWoVUm1CeGXklXzHPkW76hzzPEvxN6qQi5a4"
                  alt=""
                /> */}
                <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-white">
                  Hussain Rameez
                </h3>
                <p className="text-base leading-7 text-gray-800">Director</p>
              </li>
            </ul>
          </div>
        </main>
      </div>
    </div>
  );
}
