import React from "react";
import GeneralDesign from "../sections/generalDesign";
const Excursion55 = () => {
  return (
    <>
      <div className="lg:mx-auto lg:grid-cols-1 mt-12">
        <div className="flex">
          <GeneralDesign
            asluTitle="55' Excursion"
            describe="Elevate Your Excursion Experience"
            title="Setting Sail in Style:"
            content="Crafted with meticulous attention to detail, the vessel seamlessly blends innovative engineering, safety features, and premium amenities for your guests. We pursue to set new standards in comfort, performance, elegance and these are the most customizable vessel that can be setup according to your preference"
            imageSrc="../../../../images/Boat pages/55' Excursion/1.png"
            imageSrc2="../../../../images/Boat pages/55' Excursion/2.png"
            imageSrc3="../../../../images/Boat pages/55' Excursion/3.png"
            imageSrc4="../../../../images/Boat pages/55' Excursion/4.png"
            imageSrc6="../../../../images/Boat pages/55' Excursion/6.png"
            imageSrc7="../../../../images/Boat pages/55' Excursion/7.png"
            imageSrc8="../../../../images/Boat pages/55' Excursion/8.png"
            imageSrc9="../../../../images/Boat pages/55' Excursion/9.png"
            imageSrc10="../../../../images/Boat pages/55' Excursion/10.png"
            imageSrc11="../../../../images/Boat pages/55' Excursion/11.png"
            imageSrc12="../../../../images/Boat pages/55' Excursion/12.png"
            imageSrc13="../../../../images/Boat pages/55' Excursion/13.png"
            imageSrc14="../../../../images/Boat pages/55' Excursion/14.png"
            imageSrc15="../../../../images/Boat pages/55' Excursion/15.png"
            imageSrc16="../../../../images/Boat pages/55' Excursion/16.png"
            imageSrc17="../../../../images/Boat pages/55' Excursion/17.png"
            imageSrc18="../../../../images/Boat pages/55' Excursion/18.png"
            imageSrc19="../../../../images/Boat pages/55' Excursion/19.png"
          />
        </div>
      </div>
    </>
  );
};

export default Excursion55;
