// HeroSection.js
import React from "react";

interface GeneralProps {
  title: string;
  content: string;
  imageSrc: string;
}

function General({ title, content, imageSrc }: GeneralProps) {
  return (
    <div className="">
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div className="w-full sm:text-center lg:w-3/4 p-4 lg:p-6">
          <h2 className="text-3xl font-bold text-gray-900 sm:text-3xl text-center">
            {title}
          </h2>
          <p className="mt-6 text-base leading-7 text-gray-600 text-justify">
            {content}
          </p>
        </div>
        <div className="justify-end float-right">
          {/* rounded */}
          <img
            src={imageSrc}
            className="z-10 w-full rounded-lg"
            alt="Background"
          />
        </div>
      </div>
    </div>
  );
}

export default General;
