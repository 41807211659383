import React from "react";
import GeneralDesign from "../sections/generalDesign";
const BattheliPassenger = () => {
  return (
    <>
      <div className="lg:mx-auto lg:grid-cols-1 mt-12">
        <div className="flex">
          <GeneralDesign
            asluTitle="Battheli 38 Passenger"
            describe="First ever 100% Maldivian Flagship Vessel"
            title="First in Maldives"
            content="Starting a new era in high performance speed boats with excellent Maldivian craftsmanship fortified integrating state-of-the-art technologies for the new generation’s emerging need for speedy and comfortable rides. Designed for all sea conditions, Battheli-38 is a highly versatile and maneuverable craft, providing maximum safety and protection to everyone onboard, while also delivering exceptional performance. Customers have multiple options for deck/seating layout to opt and can be customized according to their requirements. Equipped with the latest navigational and communications systems, the crew can stay informed and always connected. This is the ultimate solution for passenger transportation with comfort and style."
            imageSrc="../../../../images/Boat pages/Battheli 38 - Passenger/boatpage images/1.png"
            imageSrc2="../../../../images/Boat pages/Battheli 38 - Passenger/boatpage images/2.png"
            imageSrc3="../../../../images/Boat pages/Battheli 38 - Passenger/boatpage images/3.png"
            imageSrc4="../../../../images/Boat pages/Battheli 38 - Passenger/boatpage images/4.png"
            imageSrc5="../../../../images/Boat pages/Battheli 38 - Passenger/boatpage images/5.png"
            imageSrc6="../../../../images/Boat pages/Battheli 38 - Passenger/boatpage images/6.png"
            imageSrc7="../../../../images/Boat pages/Battheli 38 - Passenger/boatpage images/7.png"
            imageSrc8="../../../../images/Boat pages/Battheli 38 - Passenger/boatpage images/8.png"
            imageSrc9="../../../../images/Boat pages/Battheli 38 - Passenger/boatpage images/9.png"
            imageSrc10="../../../../images/Boat pages/Battheli 38 - Passenger/boatpage images/10.png"
            imageSrc11="../../../../images/Boat pages/Battheli 38 - Passenger/boatpage images/11.png"
            imageSrc12="../../../../images/Boat pages/Battheli 38 - Passenger/boatpage images/12.png"
            imageSrc13="../../../../images/Boat pages/Battheli 38 - Passenger/boatpage images/13.png"
          />
        </div>
      </div>
    </>
  );
};

export default BattheliPassenger;
