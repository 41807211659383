import React, { FC, useEffect, useState } from "react";
import NavBoat from "../../../components/Navbar/Navboat";
import Section from "../../../components/Boat/Section";
import General from ".././sections/general";
import Specs from ".././sections/specs";
import Feature from ".././sections/feature";
import Info from ".././sections/info";
import Seating from ".././sections/seating";
import Standard from ".././sections/standard";
import { BoltIcon } from "@heroicons/react/24/outline";
import { ServerIcon } from "@heroicons/react/20/solid";
import Footer from "../../../components/Boat/Footer";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NewMega from "../../../components/Navbar/NewMega";

interface SectionItem {
  id: string;
  label: string;
}

const VipShuttle = () => {
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const sections = [
    { id: "section", label: "VIP Shuttle" },
    { id: "section1", label: "Features" },
    { id: "section2", label: "Specifications" },
    { id: "section3", label: "Seating" },
    { id: "section4", label: "Equipments" },
    // { id: "section6", label: "Optional Features" },

    // Add more sections if needed
  ];

  const features = [
    {
      name: "Rain or shine, Battheli-38 delivers.",
      description:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
      icon: BoltIcon,
    },
    {
      name: "An outboard powered vessel that excels in all conditions",
      description:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
      icon: BoltIcon,
    },
    {
      name: "Fast, stylish, and extremely capable",
      description:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
      icon: BoltIcon,
    },
    {
      name: "Power, beauty, and innovation: a new breed of high-performance speedboat",
      description:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
      icon: BoltIcon,
    },
    {
      name: "An outboard powered vessel that excels in all sea conditions",
      description:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
      icon: BoltIcon,
    },
  ];

  const sectionsData = [
    {
      name: "Features",
      features: [
        {
          name: "Length",
          spec: "38FT (11.58M)",
        },
        {
          name: "Beam",
          spec: "10ft 4in",
        },
        {
          name: "Draft",
          spec: "1ft 6in",
        },
        {
          name: "Weight",
          spec: "5200Kgs Approx",
        },
        {
          name: "Fuel Tank Capacity",
          spec: "800L",
        },
        {
          name: "Water Tank Capacity",
          spec: "80L",
        },
        {
          name: "Standard Engines",
          spec: "TWIN CXO300 DIESEL OUTBOARD",
        },
        {
          name: "Speed",
          spec: "40+ Knots",
        },
        {
          name: "Hull Design",
          spec: "DEEP V",
        },
        {
          name: "Hull Material",
          spec: "GRP",
        },
      ],
    },
  ];
  const seating = [
    {
      name: "PASSENGER SEATS (BUS SEAT)",
      icon: ServerIcon,
      image:
        "../../../../images/Boat pages/Battheli 38 - Luxury/Seating Carousel/s1.jpg",
    },
    {
      name: "BOATRAIN SIGNATURE SOFA SEATS (FRONT FACING)",
      icon: ServerIcon,
      image:
        "../../../../images/Boat pages/Battheli 38 - Luxury/Seating Carousel/s2.jpg",
    },
    {
      name: "BOATRAIN SIGNATURE SOFA SEATS (BACK-TO-BACK)",
      icon: ServerIcon,
      image:
        "../../../../images/Boat pages/Battheli 38 - Luxury/Seating Carousel/s3.jpg",
    },
    {
      name: "SEATING ARRAGEMENT",
      icon: ServerIcon,
      image:
        "../../../../images/Boat pages/Battheli 38 - Luxury/Seating Carousel/Seating Arrangement.png",
    },
  ];
  const faqs = [
    {
      question: "Standard Equipments",
      answer:
        "SKIPPER SEAT CREW <br/> SEAT x 2NOS <br/> RUBBER D-FENDER w. SS RAIL INSERT <br/>LUGGAGE STORAGE AREA<br/>NON-SKID DECK (GELCOAT)<br/>SS DECK CLEAT 6 NOS (4 on FWD, 2 at AFT)<br/>SS DECK FILLER (FUEL)<br/>SS DECK FILLER (WATER)<br/>FUEL LEVEL GUAGE WITH SENDER<br/>MANUAL BILGE PUMP<br/>DUAL USB CHARGER ON DASHBOARD<br/>COMPASS<br/>LED SEARCH LIGHT 12V DC (REMOTE CONTROL)<br/>NAVIGATIONAL LIGHT SET 12V DC<br/>WINDSCREEN WIPER COMPLETE 12V DC (SKIPPER SIDE)<br/>ELECTRIC TRIMTAB 1212<br/>BATTERY 100AH<br/>SMART BATTERY HUB KIT TRIPLE ENGINE<br/>MARINE ELECTRIC TOILET 12V DC<br/>BILGE PUMP 1750GPH 12V DC with Auto Switch & Alarm<br/>FRESH WATER PUMP 12V DC<br/>DECK WASH HOSE 50FT<br/>CABIN LIGHT",
    },
    {
      question: "STANDARD SAFETY EQUIPMENT ONBOARD",
      answer:
        "FIRST AID KIT <br/>FIRE BLANKET<br/>LIFE RING (FITTED/FLUSHED BEHIND REAR SEAT)<br/>MULTIPURPOSE TOOLBOX<br/>FIRE EXTINGUISHER WITH FIBER BOX<br/>INFLATABLE LIFE JACKET (ADULT) X 30NOS<br/>INFLATABLE LIFE JACKET (KIDS) X 4NOS<br/>ANCHOR 8KG DANFORTH GALV<br/>FLAG SET<br/>BUCKET 8L<br/>WATERPROOF RECHARGEABLE TORCH LIGHT LED<br/>BOAT HOOK<br/>FENDER F4<br/>ROPE 14MM",
    },
    {
      question: "NAVIGATION & COMMUNICATIONS EQUIPMENT",
      answer: "GPS & RADAR COMPLETE 7 <br/>MARINE VHF COMPLETE W. ANTENNA",
    },
    {
      question: "OPTIONAL ELECTRICAL SYSTEMS",
      answer:
        'MARINE DC FRIDGE C61Iax<br/>MARINE AUDIO SYSTEM (FUSION STEREO USB/AUX/IPOD/FM, 2 x FUSION SPEAKER SG 7.7" 2WAY 280W, FUSION AMPLIFER 4CH 1400W)',
    },
    {
      question: "DECKING OPTIONS",
      answer: "INFINITY LUXURY WOVEN VINYL CARPET DECKING<br/>TEAK DECKING",
    },
    {
      question: "WINDSCREEN OPTIONS",
      answer: "SIDE & AFT WINDOWS INSTALLATION",
    },
    {
      question: "INTERIOR AMENITIES UPGRADING OPTIONS",
      answer:
        "DASHBOARD FABRIC PANELING<br/>FABRIC COVER FOR GRP SEATS<br/>SIDE PANELS IN MARINE FABRICS<br/>HEADLINERS IN MARINE FABRICS<br/>LED COURTESY LIGHTING FOR SEATS",
    },
  ];
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      const active = sections.find((section: SectionItem, index: number) => {
        const nextSection = sections[index + 1];
        const nextSectionOffset = nextSection
          ? document.getElementById(nextSection.id)?.offsetTop || Infinity
          : Infinity;
        return scrollPosition < nextSectionOffset - 100;
      });

      setActiveSection(active?.id || null);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sections]);

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const yOffset = -70;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  return (
    <>
      <section className="md:h-screen relative ">
        <img
          src="../../../../images/Boat pages/Battheli 38 - Luxury/CoverImages_1.jpg"
          className="inset-0  md:w-full md:h-full  object-cover"
          alt="Background"
        />
        {/* <!--end container--> */}
      </section>
      <div className="z-20">
        <NewMega />
      </div>
      {/* <Info /> */}
      <div className="lg:mx-auto lg:grid-cols-1 mt-12">
        <div className="flex container mx-auto">
          <Section id="section1" label="">
            <General
              title="Power, beauty, and innovation: a new breed of high-performance speed
              boat"
              content="Starting a new era in high performance speed boats with excellent
              Maldivian craftsmanship fortified integrating state-of-the-art
              technologies for the new generation’s emerging need for speedy and
              comfortable rides. Designed for all sea conditions, Battheli-38 is a
              highly versatile and maneuverable craft, providing maximum safety
              and protection to everyone onboard, while also delivering
              exceptional performance. Customers have multiple options for
              deck/seating layout to opt and can be customized according to their
              requirements. Equipped with the latest navigational and
              communications systems, the crew can stay informed and always
              connected. This is the ultimate solution for passenger
              transportation with comfort and style."
              imageSrc="../../../../images/Boat pages/Battheli 38 - Luxury/SideImage.jpg"
            />
          </Section>
        </div>
      </div>
      <div className="mx-auto py-12">
        <Section id="section2" label="">
          <Feature features={features} />
          <div className="mt-12">
            <img
              src="../../../../images/Boat pages/Battheli 38 - Luxury/BannerImage_1.jpg"
              className="inset-0 w-full h-full object-cover"
              alt="Background"
            />
          </div>
        </Section>
      </div>
      <div className="container mx-auto w-4/5">
        <Carousel showThumbs={false} infiniteLoop autoPlay>
          <div className="carousel-item ">
            <img
              src="../../../../images/Boat pages/Battheli 38 - Luxury/PNG Carousel/x1.png"
              className="w-full h-full object-cover"
              alt="x1"
            />
          </div>
          <div className="carousel-item">
            <img
              src="../../../../images/Boat pages/Battheli 38 - Luxury/PNG Carousel/x2.png"
              className="w-full h-full object-cover"
              alt="x2"
            />
          </div>
          <div className="carousel-item">
            <img
              src="../../../../images/Boat pages/Battheli 38 - Luxury/PNG Carousel/x3.png"
              className="w-full h-full object-cover"
              alt="x3"
            />
          </div>
          <div className="carousel-item">
            <img
              src="../../../../images/Boat pages/Battheli 38 - Luxury/PNG Carousel/x4.png"
              className="w-full h-full object-cover"
              alt="x4"
            />
          </div>
          <div className="carousel-item">
            <img
              src="../../../../images/Boat pages/Battheli 38 - Luxury/PNG Carousel/x5.png"
              className="w-full h-full object-cover"
              alt="x5"
            />
          </div>
          <div className="carousel-item">
            <img
              src="../../../../images/Boat pages/Battheli 38 - Luxury/PNG Carousel/x6.png"
              className="w-full h-full object-cover"
              alt="x6"
            />
          </div>
        </Carousel>
      </div>

      <div className="mx-auto mt-10">
        <Section id="section3" label="">
          <Specs sections={sectionsData} />
        </Section>
      </div>

      <div className="w-full">
        <Section id="section4" label="">
          <Seating features={seating} />
        </Section>
      </div>
      <div>
        <Section id="section5" label="">
          <Standard title="Equipments" faqs={faqs} />
        </Section>
      </div>
      <div className="mt-44 bg-black">
        <Footer />
      </div>
    </>
  );
};

export default VipShuttle;
