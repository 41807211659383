import React from "react";
import GeneralDesign from "../sections/generalDesign";
const Pilot = () => {
  return (
    <>
      <div className="lg:mx-auto lg:grid-cols-1 mt-12">
        <div className="flex">
          <GeneralDesign
            asluTitle="Pilot Boat"
            describe="Discover True Maritime Excellence"
            title="Navigating Excellence"
            content="Merging cutting-edge design, advanced technology, and unwavering reliability, this vessel stands as a testament to our commitment to maritime excellence. As you review the following details, you will gain a comprehensive understanding of the vessel's capabilities, safety features, and  the exceptional craftsmanship that defines every aspect of its construction."
            imageSrc="../../../../images/Boat pages/Pilot Boat/1.png"
            imageSrc2="../../../../images/Boat pages/Pilot Boat/2.png"
            imageSrc3="../../../../images/Boat pages/Pilot Boat/3.png"
            imageSrc4="../../../../images/Boat pages/Pilot Boat/4.png"

          />
        </div>
      </div>
    </>
  );
};

export default Pilot;
