import Layout from "./components/Layouts/Layouts";
import Home from "./containers/home/index";
import Boat from "./containers/boat/index";
import About from "./containers/about/index";
import HabourPatrol from "./containers/designs/security/habour-partrol";
import Interceptor from "./containers/designs/security/interceptor";
import Logistics from "./containers/designs/security/logistics";
import SeaAmublance from "./containers/designs/security/sea-ambulance";
import TroopCarrier1 from "./containers/designs/security/troop-carrier-1";
import FullCabin from "./containers/designs/security/full-cabin";
import HalfCabin from "./containers/designs/security/half-cabin";
import VipSecurityVessel from "./containers/designs/security/vip-security-vessel";
import Passenger from "./containers/boat/battheli 38/passenger";
import VipShuttle from "./containers/boat/battheli 38/vip-shuttle";
import SportsFishing from "./containers/boat/battheli 38/sports-fishing";
import React, { useEffect } from "react";
import K65Cargo from "./containers/designs/cargo/65ft";
import K99Cargo from "./containers/designs/cargo/99ft";
import Passenger55 from "./containers/designs/passenger crafts/passengerft";
import Excursion55 from "./containers/designs/fishing & excursion/55ft";
import BattheliFishing from "./containers/designs/fishing & excursion/battheli";
import BattheliVIP from "./containers/designs/passenger crafts/vip";
import Pilot from "./containers/designs/security/pilot-boat";
import COX from "./containers/cox";
import Hyundai from "./containers/Hyundai";
import BattheliPassenger from "./containers/designs/passenger crafts/battheliFishing";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Mala from "./containers/partners/mala";
import BlogHome from "./containers/blog";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function App() {
  return (
    <Router>
      <div className="App bg-white font-bahnschrift">
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
          <Route
            path="/boat/battheli-38/passenger-version"
            element={<Passenger />}
          />
          <Route
            path="/boat/battheli-38/vip-shuttle"
            element={<VipShuttle />}
          />
          <Route
            path="/boat/battheli-38/sports-fishing"
            element={<SportsFishing />}
          />
          <Route
            path="/blog"
            element={
              <Layout>
                <BlogHome />
              </Layout>
            }
          />
          <Route
            path="/about"
            element={
              <Layout>
                <About />
              </Layout>
            }
          />
          <Route
            path="/designs/security/interceptor"
            element={
              <Layout>
                <Interceptor />
              </Layout>
            }
          />
          <Route
            path="/designs/security/habour-patrol"
            element={
              <Layout>
                <HabourPatrol />
              </Layout>
            }
          />
          <Route
            path="/designs/security/logistics"
            element={
              <Layout>
                <Logistics />
              </Layout>
            }
          />
          <Route
            path="/designs/security/kf38-full-cabin-security-vessel"
            element={
              <Layout>
                <FullCabin />
              </Layout>
            }
          />
          <Route
            path="/designs/security/kf38-half-cabin-security-vessel"
            element={
              <Layout>
                <HalfCabin />
              </Layout>
            }
          />

          <Route
            path="/designs/security/pilot-boat"
            element={
              <Layout>
                <Pilot />
              </Layout>
            }
          />

          <Route
            path="/designs/security/sea-ambulance"
            element={
              <Layout>
                <SeaAmublance />
              </Layout>
            }
          />
          <Route
            path="/designs/security/troop-carrier-1"
            element={
              <Layout>
                <TroopCarrier1 />
              </Layout>
            }
          />
          <Route
            path="/designs/security/vip-security-vessel"
            element={
              <Layout>
                <VipSecurityVessel />
              </Layout>
            }
          />
          <Route
            path="/designs/cargo/k65-cargo"
            element={
              <Layout>
                <K65Cargo />
              </Layout>
            }
          />
          <Route
            path="/designs/cargo/99ft-cargo-dhoani"
            element={
              <Layout>
                <K99Cargo />
              </Layout>
            }
          />
          {/* <Route
            path="/designs/fishing-and-excursion/55ft-diving-&-excursion-dhoani"
            element={
              <Layout>
                <FT55Diving />
              </Layout>
            }
          /> */}
          {/* <Route
            path="/designs/fishing-and-excursion/fishing"
            element={
              <Layout>
                <BattheliFishing />
              </Layout>
            }
          /> */}
          <Route
            path="/designs/cargo/99ft-cargo-dhoani"
            element={
              <Layout>
                <K99Cargo />
              </Layout>
            }
          />
          <Route
            path="/designs/passenger/passenger-craft-55"
            element={
              <Layout>
                <Passenger55 />
              </Layout>
            }
          />
          <Route
            path="/designs/fishing-and-excursion/55ft-diving-&-excursion-dhoani"
            element={
              <Layout>
                <Excursion55 />
              </Layout>
            }
          />
          <Route
            path="/designs/fishing-and-excursion/fishing"
            element={
              <Layout>
                <BattheliFishing />
              </Layout>
            }
          />
          <Route
            path="/designs/passenger/passenger-version"
            element={
              <Layout>
                <BattheliPassenger />
              </Layout>
            }
          />
          <Route
            path="/designs/passenger/vip-shuttle"
            element={
              <Layout>
                <BattheliVIP />
              </Layout>
            }
          />
          <Route
            path="/cox"
            element={
              <Layout>
                <COX />
              </Layout>
            }
          />
          <Route
            path="/hyundai"
            element={
              <Layout>
                <Hyundai />
              </Layout>
            }
          />
          <Route
            path="/partners/mala"
            element={
              <Layout>
                <Mala />
              </Layout>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}
