import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Boat from "../../components/Boat/Boat";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <div className="">
      <Navbar />
      <Boat />
      <div className="bg-white py-20">
        <div className="mx-auto w-full">
          <div className="border-main w-4/5 md:w-2/5 mx-auto border-b-4">
            <h2 className="text-center  text-4xl mb-1 font-semibold leading-8 text-black uppercase">
              Our Partners
            </h2>
          </div>

          <div className="mx-auto items-center mt-20 grid grid-cols-1 gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-2 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <img
              className="col-span-2 max-h-20 w-full object-contain lg:col-span-1"
              src="../../../images/Logos/DOC_Logo_Images/DOC_Logo_ImgID1.png"
              alt="DOC"
            />
            <img
              className="col-span-2 max-h-20 w-full object-contain lg:col-span-1"
              src="../../../images/Logos/FIM NEWEST.png"
              alt="MALA"
            />
            <Link to="/partners/mala">
              <img
                className="col-span-2 max-h-20 w-full object-contain lg:col-span-1"
                src="../../../images/Logos/malabg.png"
                alt="MALA"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
