import React from "react";
import GeneralDesign from "../sections/generalDesign";
const Pilot = () => {
  return (
    <>
      <div className="lg:mx-auto lg:grid-cols-1 mt-12">
        <div className="flex">
          <GeneralDesign
            asluTitle="KF38 Security Vessel - Half Cabin"
            describe="Discover True Maritime Excellence"
            title="Safety at Sea"
            content="Introducing the Kalhuoh Fummi 38 Security Vessel (Half Cabin), the ultimate solution for
              safeguarding and patrolling the inshore waters and harbours. This vessel is a highly
              versatile and manoeuvrable craft, designed to provide maximum comfort and protection
              to all personnel on board, while also delivering exceptional performance and efficiency.
              Equipped with the latest navigation and communication systems, the crew can stay
              informed and always connected, ensuring optimal safety and efficiency."
            imageSrc="../../../../images/Boat pages/KF38 Half Cabin/1.png"
            imageSrc2="../../../../images/Boat pages/KF38 Half Cabin/2.png"
            imageSrc3="../../../../images/Boat pages/KF38 Half Cabin/3.png"
            imageSrc4="../../../../images/Boat pages/KF38 Half Cabin/4.png"
            imageSrc5="../../../../images/Boat pages/KF38 Half Cabin/5.png"
            imageSrc6="../../../../images/Boat pages/KF38 Half Cabin/6.png"
            imageSrc7="../../../../images/Boat pages/KF38 Half Cabin/7.png"
            imageSrc8="../../../../images/Boat pages/KF38 Half Cabin/8.png"
            imageSrc9="../../../../images/Boat pages/KF38 Half Cabin/9.png"
            imageSrc10="../../../../images/Boat pages/KF38 Half Cabin/10.png"

          />
        </div>
      </div>
    </>
  );
};

export default Pilot;
