import React, { useState } from "react";
import Generator from "./generator";
const sampleTabData = [
  {
    id: 1,
    title: "HD401 Series",
    image: "../../../../images/Boat pages/Hyundai/HD401_HD401T.jpg",
    engine: "../../../../images/Boat pages/Hyundai/HD401_HD401T_DWG.jpg",
    thankolhu1: { title: "HD401", description: "40PS / 2,500rpm" },
    thankolhu2: { title: "HD401T", description: " 60PS / 3,000rpm" },
    content: "Content for Tab 1",
  },
  {
    id: 2,
    title: "HD403 Series",
    image: "../../../../images/Boat pages/Hyundai/HD403_HD403T.jpg",
    engine: "../../../../images/Boat pages/Hyundai/HD403_HD403T_DWG.jpg",
    thankolhu1: { title: "HD403", description: "70PS / 2,700rpm" },
    thankolhu2: { title: "HD403T", description: " 100PS / 2,700rpm" },
    content: "Content for Tab 1",
  },
  {
  id: 3,
  title: "HD403TA Series",
  image: "../../../../images/Boat pages/Hyundai/HD403TA_HD403TA2.jpg",
  engine: "../../../../images/Boat pages/Hyundai/HD403TA_HD403TA2_DWG.jpg",
  thankolhu1: { title: "HD403TA", description: "120PS / 2,700rpm" },
  thankolhu2: { title: "HD403TA2", description: " 150PS / 3,000rpm" },
  content: "Content for Tab 1",
},
{
  id: 4,
  title: "HD404 Series",
  image: "../../../../images/Boat pages/Hyundai/HD404TA.jpg",
  engine: "../../../../images/Boat pages/Hyundai/HD404TA_DWG.jpg",
  thankolhu1: { title: "HD404TA", description: "175PS / 2,500rpm" },
  content: "Content for Tab 1",
},

{
  id: 5,
  title: "HD611 Series",
  image: "../../../../images/Boat pages/Hyundai/HD611T_HD611TA.jpg",
  engine: "../../../../images/Boat pages/Hyundai/HD611T_HD611TA_DWG.jpg",
  thankolhu1: { title: "HD611T", description: "260PS / 2,000rpm" },
  thankolhu2: { title: "HD611TA", description: " 330PS / 2,100rpm" },

  content: "Content for Tab 1",
},

{
  id: 6,
  title: "HD614 Series",
  image: "../../../../images/Boat pages/Hyundai/HD614TA.jpg",
  engine: "../../../../images/Boat pages/Hyundai/HD614TA_DWG.jpg",
  thankolhu1: { title: "HD614TA", description: "410PS / 2,000rpm" },

  content: "Content for Tab 1",
},

{
  id: 7,
  title: "HD612 Series",
  image: "../../../../images/Boat pages/Hyundai/HD612TA5.jpg",
  engine: "../../../../images/Boat pages/Hyundai/HD612TA5_DWG.jpg",
  thankolhu1: { title: "HD401", description: "500PS / 2,000rpm" },
  content: "Content for Tab 1",
},
{
  id: 8,
  title: "HD615 Series",
  image: "../../../../images/Boat pages/Hyundai/HD615TA_HD615TA6.jpg",
  engine: "../../../../images/Boat pages/Hyundai/HD615TA_HD615TA6_DWG.jpg",
  thankolhu1: { title: "HD615TA", description: "550PS / 2,000rpm" },
  thankolhu2: { title: "HD615TA6", description: "600PS / 2,000rpm" },
  content: "Content for Tab 1",
},

];

const Hyundai = () => {
  const [activeTab, setActiveTab] = useState(sampleTabData[0]);

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
  };

  function renderTableForTab(tabName: any) {
    if (tabName === "HD401 Series") {
      return renderTab1();
    } else if (tabName === "HD403 Series") {
      return renderTab2();
    } else if (tabName === "HD403TA Series") {
      return renderTab3();
    } else if (tabName === "HD404 Series") {
      return renderTab4();
    } else if (tabName === "HD611 Series") {
      return renderTab5();
    }else if (tabName === "HD614 Series") {
      return renderTab6();
    }else if (tabName === "HD612 Series") {
      return renderTab7();
    }else if (tabName === "HD615 Series") {
      return renderTab8();
    }
    
    // Add additional cases for more tabs as needed
    return null; // Default case, return null if no match
  }

  function renderTab1() {
    return (
      <div className="w-full p-4">
        <table className="w-full table-auto border border-gray-900">
          <thead className="bg-blue-900 text-white">
            <tr>
              <th colSpan={7} className="p-2 border border-gray-500 uppercase">
                ENGINE SPECIFICATIONS
              </th>
            </tr>
          </thead>
          <tbody className="font-light">
            <tr className="bg-blue-900 text-white">
              <th colSpan={2} className="p-2 border border-gray-500">
                Model
              </th>
              <th className="p-2 border border-gray-500">Unit</th>
              <th colSpan={2} className="p-2 border border-gray-500">
                HD401
              </th>
              <th colSpan={2} className="p-2 border border-gray-500">
                HD401T
              </th>
            </tr>
            <tr>
              <td colSpan={2} className="p-2 border border-gray-500">
                Engine Type
              </td>
              <td className="p-2 border border-gray-500"></td>
              <td colSpan={4} className="p-2 border border-gray-500">
                4 Cycle, Vertical, Water cooled diesel engine
              </td>
            </tr>
            <tr>
              <td rowSpan={3} className="p-2 border border-gray-500">
                Rating <br />
                Ouput
              </td>
              <td className="p-2 border border-gray-500 text-center">Heavy Duty(1)</td>
              <td className="p-2 border border-gray-500 text-center">PS / RPM</td>
              <td colSpan={2} className="p-2 border border-gray-500 text-center">
                40/2,500
              </td>
              <td colSpan={2} className="p-2 border border-gray-500 text-center">
                60/2,500
              </td>
            </tr>
            <tr>
              <td className="p-2 border border-gray-500 text-center">Medium Duty(2)</td>
              <td className="p-2 border border-gray-500 text-center">PS / RPM</td>
              <td colSpan={2} className="p-2 border border-gray-500 text-center">
                50/2,700
              </td>
              <td colSpan={2} className="p-2 border border-gray-500 text-center">
                70/2,700
              </td>
            </tr>
            <tr>
              <td className="p-2 border border-gray-500 text-center">Light Duty(3)</td>
              <td className="p-2 border border-gray-500 text-center">PS / RPM</td>
              <td colSpan={2} className="p-2 border border-gray-500 text-center">
                60/3,000
              </td>
              <td colSpan={2} className="p-2 border border-gray-500 text-center">
              80/3,000
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="p-2 border border-gray-500">
                No of Cylinders
              </td>
              <td className="p-2 border border-gray-500"></td>
              <td
                colSpan={4}
                className="p-2 border border-gray-500 text-center"
              >
                4 in line
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="p-2 border border-gray-500">
                Bore × Stroke
              </td>
              <td className="p-2 border border-gray-500 text-center">mm</td>
              <td
                colSpan={4}
                className="p-2 border border-gray-500 text-center"
              >
                91.1×100
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="p-2 border border-gray-500">
                Displacement
              </td>
              <td className="p-2 border border-gray-500 text-center">cc</td>
              <td
                colSpan={4}
                className="p-2 border border-gray-500 text-center"
              >
                2,607
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="p-2 border border-gray-500">
                Aspiration
              </td>
              <td className="p-2 border border-gray-500 text-center"></td>

              <td
                colSpan={2}
                className="p-2 border border-gray-500 text-center"
              >
                NA
              </td>
              <td
                colSpan={2}
                className="p-2 border border-gray-500 text-center"
              >
                TC
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="p-2 border border-gray-500">
                Compression ratio
              </td>
              <td className="p-2 border border-gray-500 text-center"></td>

              <td
                colSpan={2}
                className="p-2 border border-gray-500 text-center"
              >
                22 : 1
              </td>
              <td
                colSpan={2}
                className="p-2 border border-gray-500 text-center"
              >
                22 : 1
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="p-2 border border-gray-500">
                Firing order{" "}
              </td>
              <td className="p-2 border border-gray-500 text-center"></td>

              <td
                colSpan={2}
                className="p-2 border border-gray-500 text-center"
              >
                1-3-4-2
              </td>
              <td
                colSpan={2}
                className="p-2 border border-gray-500 text-center"
              >
                1-3-4-2{" "}
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="p-2 border border-gray-500">
                Fuel injection type{" "}
              </td>
              <td className="p-2 border border-gray-500 text-center"></td>

              <td
                colSpan={4}
                className="p-2 border border-gray-500 text-center"
              >
                Bosch type with all speed control{" "}
              </td>
            </tr>

            <tr>
              <td colSpan={2} className="p-2 border border-gray-500">
                Lubrication oil pan capacity
              </td>
              <td className="p-2 border border-gray-500 text-center">ℓ</td>
              <td
                colSpan={2}
                className="p-2 border border-gray-500 text-center">
                  5.4
                </td>
                <td
                colSpan={2}
                className="p-2 border border-gray-500 text-center">
                  5.4
                </td>
            </tr>
            <tr>
          <td colSpan={2} className="p-2 border border-gray-500">
            Max. allowable oil temp
          </td>
          <td className="p-2 border border-gray-500 text-center">°C</td>
          <td colSpan={4} className="p-2 border border-gray-500 text-center">
            120
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500">
            Starting system
          </td>
          <td className="p-2 border border-gray-500"></td>
          <td colSpan={4} className="p-2 border border-gray-500 text-center">
            Electric starting by starter motor
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500">
            Start motor capacity
          </td>
          <td className="p-2 border border-gray-500 text-center">V-KW</td>
          <td colSpan={2} className="p-2 border border-gray-500 text-center">
            12-2.2
          </td>
          <td colSpan={2} className="p-2 border border-gray-500 text-center">
            12-2.2
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500">
            Alternator capacity
          </td>
          <td className="p-2 border border-gray-500 text-center">V-A</td>
          <td colSpan={4} className="p-2 border border-gray-500 text-center">
            12-65
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500">
            Cooling system
          </td>
          <td className="p-2 border border-gray-500"></td>
          <td colSpan={4} className="p-2 border border-gray-500 text-center">
            Indirect sea water cooling with heat exchanger
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500">
            Cooling water capacity
          </td>
          <td className="p-2 border border-gray-500 text-center">ℓ</td>
          <td colSpan={4} className="p-2 border border-gray-500 text-center">
            5.5
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500">
            Fresh water pump type
          </td>
          <td className="p-2 border border-gray-500 text-center"></td>
          <td colSpan={4} className="p-2 border border-gray-500 text-center">
            Centrifugal type, driven by gear
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500">
            Sea water pump type
          </td>
          <td className="p-2 border border-gray-500"></td>
          <td colSpan={4} className="p-2 border border-gray-500 text-center">
            Rubber impeller type, driven by belt
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500">
            Engine size (L×W×H)
          </td>
          <td className="p-2 border border-gray-500 text-center">mm</td>
          <td colSpan={4} className="p-2 border border-gray-500 text-center">
            993 × 679 × 729
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500">
            Engine dry weight (Without Transmission)
          </td>
          <td className="p-2 border border-gray-500 text-center">kg</td>
          <td colSpan={2} className="p-2 border border-gray-500 text-center">
            288
          </td>
          <td colSpan={2} className="p-2 border border-gray-500 text-center">
            310
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500">
            STD gear ratio(Optional)
          </td>
          <td className="p-2 border border-gray-500 text-center"></td>
          <td colSpan={4} className="p-2 border border-gray-500 text-center">
            1.64, 2.07, 2.52, 2.96, 3.32
          </td>
        </tr>
          </tbody>
        </table>
      </div>
    );
  }

  function renderTab2() {
    return (
      <div className="w-full p-4">
        <table className="w-full table-auto border border-gray-900">
          <thead className="bg-blue-900 text-white">
            <tr>
              <th colSpan={5} className="p-2 border border-gray-500 uppercase">
                ENGINE SPECIFICATIONS
              </th>
            </tr>
          </thead>
          <tbody className="font-light">
            {/* Header row */}
            <tr className="bg-blue-900 text-white">
              <th colSpan={2} className="p-2 border border-gray-500">
                Model
              </th>
              <th className="p-2 border border-gray-500">Unit</th>
              <th className="p-2 border border-gray-500">HD403</th>
              <th className="p-2 border border-gray-500">HD403T</th>
            </tr>
            {/* Data rows */}
            <tr>
              <td colSpan={2} className="p-2 border border-gray-500 text-left">
                Engine type
              </td>
              <td className="p-2 border border-gray-500 text-center"></td>
              <td colSpan={2} className="p-2 border border-gray-500 text-center">
                4Cycle, Vertical, Water cooled diesel engine
              </td>
            </tr>
            {/* Repeat for each row, ensure the first td has text-left class for alignment */}
            <tr>
              <td colSpan={2} className="p-2 border border-gray-500 text-left">
                Rating Output
              </td>
              <td className="p-2 border border-gray-500 text-center">PS(kw)/RPM</td>
              <td className="p-2 border border-gray-500 text-center">70(51) / 2,700</td>
              <td className="p-2 border border-gray-500 text-center">100(73) / 2,700</td>
            </tr>
            <tr>
          <td colSpan={2} className="p-2 border border-gray-500 text-left">
            No. of Cylinders
          </td>
          <td className="p-2 border border-gray-500 text-center"></td>
          <td colSpan={2} className="p-2 border border-gray-500 text-center">
            4 in Line
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500 text-left">
            Bore × Stroke
          </td>
          <td className="p-2 border border-gray-500 text-center">mm</td>
          <td className="p-2 border border-gray-500 text-center">104 × 105</td>
          <td className="p-2 border border-gray-500 text-center">100 × 105</td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500 text-left">
            Displacement
          </td>
          <td className="p-2 border border-gray-500 text-center">cc</td>
          <td className="p-2 border border-gray-500 text-center">3,568</td>
          <td className="p-2 border border-gray-500 text-center">3,298</td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500 text-left">
            Aspiration
          </td>
          <td className="p-2 border border-gray-500 text-center"></td>
          <td className="p-2 border border-gray-500 text-center">NA</td>
          <td className="p-2 border border-gray-500 text-center">TC</td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500 text-left">
            Compression ratio
          </td>
          <td className="p-2 border border-gray-500 text-center"></td>
          <td className="p-2 border border-gray-500 text-center">17.5 : 1</td>
          <td className="p-2 border border-gray-500 text-center">16 : 1</td>
        </tr>
            {/* ... additional rows ... */}
            <tr>
        <td colSpan={2} className="p-2 border border-gray-500 text-left">
            Firing order
          </td>
        <td className="p-2 border border-gray-500 text-center"></td>
        <td className="p-2 border border-gray-500 text-center">1-3-4-2</td>
           <td className="p-2 border border-gray-500 text-center">1-3-4-2</td>
          </tr>

            <tr>
          <td colSpan={2} className="p-2 border border-gray-500 text-left">
            Fuel injection type
          </td>
          <td className="p-2 border border-gray-500 text-center"></td>
          <td colSpan={2} className="p-2 border border-gray-500 text-center">
            Bosch type with all speed control
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500 text-left">
            Fuel consumption
          </td>
          <td className="p-2 border border-gray-500 text-center">ℓ / h</td>
          <td className="p-2 border border-gray-500 text-center">12.3</td>
          <td className="p-2 border border-gray-500 text-center">18</td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500 text-left">
            Lubrication oil pan capacity
          </td>
          <td className="p-2 border border-gray-500 text-center">ℓ</td>
          <td className="p-2 border border-gray-500 text-center">8.5</td>
          <td className="p-2 border border-gray-500 text-center">8.5</td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500 text-left">
            Lubrication oil pan pressure
          </td>
          <td className="p-2 border border-gray-500 text-center">Kg / cm²</td>
          <td className="p-2 border border-gray-500 text-center">
            Idle: 1.5, Full: 3~5
          </td>
          <td className="p-2 border border-gray-500 text-center">
            Idle: 1.5, Full: 3~5
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500 text-left">
            Starting System
          </td>
          <td className="p-2 border border-gray-500 text-center"></td>
          <td colSpan={2} className="p-2 border border-gray-500 text-center">
            Electric starting by starter motor
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500 text-left">
            Start motor capacity
          </td>
          <td className="p-2 border border-gray-500 text-center">V-KW</td>
          <td className="p-2 border border-gray-500 text-center">24-5</td>
          <td className="p-2 border border-gray-500 text-center">24-5</td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500 text-left">
            Alternator capacity
          </td>
          <td className="p-2 border border-gray-500 text-center">V-A</td>
          <td className="p-2 border border-gray-500 text-center">24-40</td>
          <td className="p-2 border border-gray-500 text-center">24-40</td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500 text-left">
            Cooling system
          </td>
          <td className="p-2 border border-gray-500 text-center"></td>
          <td colSpan={2} className="p-2 border border-gray-500 text-center">
            Indirect sea water cooling with heat exchanger
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500 text-left">
            Cooling water capacity
          </td>
          <td className="p-2 border border-gray-500 text-center">ℓ</td>
          <td className="p-2 border border-gray-500 text-center">10</td>
          <td className="p-2 border border-gray-500 text-center">10</td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500 text-left">
            Fresh water pump type
          </td>
          <td className="p-2 border border-gray-500 text-center"></td>
          <td colSpan={2} className="p-2 border border-gray-500 text-center">
            Centrifugal type, driven by gear
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500 text-left">
            Sea water pump type
          </td>
          <td className="p-2 border border-gray-500 text-center"></td>
          <td colSpan={2} className="p-2 border border-gray-500 text-center">
            Rubber impeller type, driven by belt
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500 text-left">
            Engine size (L×W×H)
          </td>
          <td className="p-2 border border-gray-500 text-center">mm</td>
          <td className="p-2 border border-gray-500 text-center">1,115 × 734 × 895</td>
          <td className="p-2 border border-gray-500 text-center">1,115 × 734 × 895</td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500 text-left">
            Engine dry weight (Without Transmission)
          </td>
          <td className="p-2 border border-gray-500 text-center">kg</td>
          <td className="p-2 border border-gray-500 text-center">350</td>
          <td className="p-2 border border-gray-500 text-center">360</td>
        </tr>
        <tr>
          <td colSpan={2} className="p-2 border border-gray-500 text-left">
            STD gear ratio(Optional)
          </td>
          <td className="p-2 border border-gray-500 text-center"></td>
          <td colSpan={2} className="p-2 border border-gray-500 text-center">
            1.64, 2.07, 2.52, 2.96, 3.32
          </td>
        </tr>
          </tbody>
        </table>
      </div>
    );
    
    
    
  }

  function renderTab3() {
    return (
      <div className="w-full p-4">
        <table className="w-full table-auto border border-gray-900">
          <thead className="bg-blue-900 text-white">
            <tr>
              <th colSpan={5} className="p-2 border border-gray-500 uppercase">
                ENGINE SPECIFICATIONS
              </th>
            </tr>
          </thead>
          <tbody className="font-light">
            {/* Header row */}
            <tr className="bg-blue-900 text-white">
              <th colSpan={2} className="p-2 border border-gray-500">
                Model
              </th>
              <th className="p-2 border border-gray-500">Unit</th>
              <th className="p-2 border border-gray-500">HD403TA</th>
              <th className="p-2 border border-gray-500">HD403TA2</th>
            </tr>
            {/* Data rows */}
            <tr>
              <td colSpan={2} className="p-2 border border-gray-500 text-left">
                Engine type
              </td>
              <td className="p-2 border border-gray-500 text-center"></td>
              <td colSpan={2} className="p-2 border border-gray-500 text-center">
                4Cycle, Vertical, Water cooled diesel engine
              </td>
            </tr>
            <tr>
  <td colSpan={2} className="p-2 border border-gray-500 text-left">
    Rating Output
  </td>
  <td className="p-2 border border-gray-500 text-center">PS(kw)/RPM</td>
  <td className="p-2 border border-gray-500 text-center">120(88) / 2,700</td>
  <td className="p-2 border border-gray-500 text-center">150(110) / 3,000</td>
</tr>
<tr>
  <td colSpan={2} className="p-2 border border-gray-500 text-left">
    No. of Cylinders
  </td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">4 in Line</td>
  
</tr>
<tr>
  <td colSpan={2} className="p-2 border border-gray-500 text-left">
    Bore × Stroke
  </td>
  <td className="p-2 border border-gray-500 text-center">mm</td>
  <td className="p-2 border border-gray-500 text-center">100 × 105</td>
  <td className="p-2 border border-gray-500 text-center">104 × 115</td>
</tr>
<tr>
  <td colSpan={2} className="p-2 border border-gray-500 text-left">
    Displacement
  </td>
  <td className="p-2 border border-gray-500 text-center">cc</td>
  <td className="p-2 border border-gray-500 text-center">3,298</td>
  <td className="p-2 border border-gray-500 text-center">3,907</td>
</tr>
<tr>
  <td colSpan={2} className="p-2 border border-gray-500 text-left">
    Aspiration
  </td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">TCI</td>
  <td className="p-2 border border-gray-500 text-center">TCI</td>
</tr>
<tr>
  <td colSpan={2} className="p-2 border border-gray-500 text-left">
    Compression ratio
  </td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">16 : 1</td>
  <td className="p-2 border border-gray-500 text-center">16.5 : 1</td>
</tr>

            {/* ... (repeat for each row of data) ... */}
            <tr>
              <td colSpan={2} className="p-2 border border-gray-500 text-left">
                Firing order
              </td>
              <td className="p-2 border border-gray-500 text-center"></td>
              <td className="p-2 border border-gray-500 text-center">1-3-4-2</td>
              <td className="p-2 border border-gray-500 text-center">1-3-4-2</td>
            </tr>
            {/* ... (continue adding rows for the rest of the table data) ... */}

            <tr>
  <td colSpan={2} className="p-2 border border-gray-500 text-left">
    Fuel injection type
  </td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">
    Bosch type with all speed control
  </td>

</tr>
<tr>
  <td colSpan={2} className="p-2 border border-gray-500 text-left">
    Fuel consumption
  </td>
  <td className="p-2 border border-gray-500 text-center">ℓ / h</td>
  <td className="p-2 border border-gray-500 text-center">22</td>
  <td className="p-2 border border-gray-500 text-center">26.3</td>
</tr>
<tr>
  <td colSpan={2} className="p-2 border border-gray-500 text-left">
    Lubrication oil pan capacity
  </td>
  <td className="p-2 border border-gray-500 text-center">ℓ</td>
  <td className="p-2 border border-gray-500 text-center">8.5</td>
  <td className="p-2 border border-gray-500 text-center">8.5</td>
</tr>
<tr>
  <td colSpan={2} className="p-2 border border-gray-500 text-left">
    Lubrication oil pan pressure
  </td>
  <td className="p-2 border border-gray-500 text-center">Kg / cm²</td>
  <td className="p-2 border border-gray-500 text-center">
    Idle: 1.5~3, Full: 3~5
  </td>
  <td className="p-2 border border-gray-500 text-center">
    Idle: 1.5~3, Full: 3~5
  </td>
</tr>
<tr>
  <td colSpan={2} className="p-2 border border-gray-500 text-left">
    Starting System
  </td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">
    Electric starting by starter motor
  </td>

</tr>
<tr>
  <td colSpan={2} className="p-2 border border-gray-500 text-left">
    Start motor capacity
  </td>
  <td className="p-2 border border-gray-500 text-center">V-KW</td>
  <td className="p-2 border border-gray-500 text-center">24-5</td>
  <td className="p-2 border border-gray-500 text-center">24-5</td>
</tr>
<tr>
  <td colSpan={2} className="p-2 border border-gray-500 text-left">
    Alternator capacity
  </td>
  <td className="p-2 border border-gray-500 text-center">V-A</td>
  <td className="p-2 border border-gray-500 text-center">24-40</td>
  <td className="p-2 border border-gray-500 text-center">24-40</td>
</tr>
<tr>
  <td colSpan={2} className="p-2 border border-gray-500 text-left">
    Cooling system
  </td>
  <td className="p-2 border border-gray-500 text-center"></td>

  <td colSpan={2} className="p-2 border border-gray-500 text-center">
    Indirect sea water cooling with heat exchanger
  </td>
</tr>
<tr>
  <td colSpan={2} className="p-2 border border-gray-500 text-left">
    Cooling water capacity
  </td>
  <td className="p-2 border border-gray-500 text-center">ℓ</td>
  <td className="p-2 border border-gray-500 text-center">10</td>
  <td className="p-2 border border-gray-500 text-center">10</td>
</tr>
<tr>
  <td colSpan={2} className="p-2 border border-gray-500 text-left">
    Fresh water pump type
  </td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">
    Centrifugal type, driven by gear
  </td>

</tr>
<tr>
  <td colSpan={2} className="p-2 border border-gray-500 text-left">
    Sea water pump type
  </td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td colSpan={2}  className="p-2 border border-gray-500 text-center">
    Rubber impeller type, driven by belt
  </td>

</tr>
<tr>
  <td colSpan={2} className="p-2 border border-gray-500 text-left">
    Engine size (L×W×H)
  </td>
  <td className="p-2 border border-gray-500 text-center">mm</td>
  <td className="p-2 border border-gray-500 text-center">1,115 × 734 × 895</td>
  <td className="p-2 border border-gray-500 text-center">1,115 × 734 × 895</td>
</tr>
<tr>
  <td colSpan={2} className="p-2 border border-gray-500 text-left">
    Engine dry weight (Without Transmission)
  </td>
  <td className="p-2 border border-gray-500 text-center">kg</td>
  <td className="p-2 border border-gray-500 text-center">380</td>
  <td className="p-2 border border-gray-500 text-center">420</td>
</tr>
<tr>
  <td colSpan={2} className="p-2 border border-gray-500 text-left">
    STD gear ratio(Optional)
  </td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">
    1.64, 2.07, 2.52, 2.96, 3.32
  </td>
</tr>
          </tbody>
        </table>
      </div>
    );
    
  }

  function renderTab4() {
 
    return (
      <div className="w-full p-4">
    <table className="w-full table-auto border border-gray-900">
      <thead className="bg-blue-900 text-white">
        <tr>
          <th colSpan={5} className="p-2 border border-gray-500 uppercase">
            ENGINE SPECIFICATIONS
          </th>
        </tr>
      </thead>
    
          <tbody className="font-light">
          <tr className="bg-blue-900 text-white">
              <th className="p-2 border border-gray-500 uppercase">Model</th>
              <th className="p-2 border border-gray-500 uppercase">Unit</th>
              <th className="p-2 border border-gray-500 uppercase">HD404TA</th>
            </tr>
            <tr>
              <td className="p-2 border border-gray-500 text-left">Engine type</td>
              <td className="p-2 border border-gray-500 text-left"></td>
              <td className="p-2 border border-gray-500 text-center">
                4Cycle, Vertical, Water cooled diesel engine
              </td>
            </tr>
            <tr>
              <td className="p-2 border border-gray-500 text-left">Rating Output</td>
              <td className="p-2 border border-gray-500 text-center">PS(kw)/RPM</td>
              <td className="p-2 border border-gray-500 text-center">
                175(128) / 2,500
              </td>
            </tr>
            <tr>
              <td className="p-2 border border-gray-500 text-left">No. of Cylinders</td>
              <td className="p-2 border border-gray-500 text-center"></td>
              <td className="p-2 border border-gray-500 text-center">
                4 in Line
              </td>
            </tr>
            <tr>
              <td className="p-2 border border-gray-500 text-left">Bore × Stroke</td>
              <td className="p-2 border border-gray-500 text-center">mm</td>
              <td className="p-2 border border-gray-500 text-center">
                103 × 118
              </td>
            </tr>
            <tr>
              <td className="p-2 border border-gray-500 text-left">Displacement</td>
              <td className="p-2 border border-gray-500 text-center">cc</td>
              <td className="p-2 border border-gray-500 text-center">
                3,933
              </td>
            </tr>
            <tr>
              <td className="p-2 border border-gray-500 text-left">Aspiration</td>
              <td className="p-2 border border-gray-500 text-center"></td>
              <td className="p-2 border border-gray-500 text-center">
                TCI
              </td>
            </tr>
            <tr>
              <td className="p-2 border border-gray-500 text-left">Compression ratio</td>
              <td className="p-2 border border-gray-500 text-center"></td>
              <td className="p-2 border border-gray-500 text-center">
                17.0 : 1
              </td>
            </tr>
            <tr>
  <td className="p-2 border border-gray-500 text-left">Firing order</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">1-3-4-2</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Fuel injection type</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">Denso Common Rail System</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Fuel consumption</td>
  <td className="p-2 border border-gray-500 text-center">ℓ / h</td>
  <td className="p-2 border border-gray-500 text-center">33.8</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Lubrication oil pan capacity</td>
  <td className="p-2 border border-gray-500 text-center">ℓ</td>
  <td className="p-2 border border-gray-500 text-center">14</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Lubrication oil pan pressure</td>
  <td className="p-2 border border-gray-500 text-center">Kg / cm²</td>
  <td className="p-2 border border-gray-500 text-center">3.0~5.0</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Starting System</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">Electric starting by starter motor</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Start motor capacity</td>
  <td className="p-2 border border-gray-500 text-center">V-KW</td>
  <td className="p-2 border border-gray-500 text-center">24 – 5.5</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Alternator capacity</td>
  <td className="p-2 border border-gray-500 text-center">V-A</td>
  <td className="p-2 border border-gray-500 text-center">24 – 70</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Cooling system</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">Indirect sea water cooling with heat exchanger</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Cooling water capacity</td>
  <td className="p-2 border border-gray-500 text-center">ℓ</td>
  <td className="p-2 border border-gray-500 text-center">15</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Fresh water pump type</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">Centrifugal type, driven by gear</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Sea water pump type</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">Rubber impeller type, driven by gear</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Engine size (L×W×H)</td>
  <td className="p-2 border border-gray-500 text-center">mm</td>
  <td className="p-2 border border-gray-500 text-center">1,215 × 750 × 895</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Engine dry weight (Without Transmission)</td>
  <td className="p-2 border border-gray-500 text-center">kg</td>
  <td className="p-2 border border-gray-500 text-center">380</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">STD gear ratio(Optional)</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">1.61, 2.06, 2.45, 2.82, 3.12, 3.46</td>
</tr>

            {/* ... additional rows ... */}
          </tbody>
        </table>
      </div>
    );
    
    
    
  }

  function renderTab5() {
    return (

      <div className="w-full p-4">
      <table className="w-full table-auto border border-gray-900">
        <thead className="bg-blue-900 text-white">
          <tr>
            <th colSpan={5} className="p-2 border border-gray-500 uppercase">
              ENGINE SPECIFICATIONS
            </th>
          </tr>
        </thead>

          <tbody className="font-light">
          <tr className="bg-blue-900 text-white">
              <th className="p-2 border border-gray-500">Model</th>
              <th className="p-2 border border-gray-500">Unit</th>
              <th className="p-2 border border-gray-500">HD611T</th>
              <th className="p-2 border border-gray-500">HD611TA</th>
            </tr>
            <tr>
              <td className="p-2 border border-gray-500 text-left">Engine type</td>
              <td className="p-2 border border-gray-500 text-center"></td>
              <td className="p-2 border border-gray-500 text-center" colSpan={2}>
                4Cycle, Vertical, Water cooled diesel engine
              </td>
            </tr>
            {/* Repeat for each specification row */}
            <tr>
              <td className="p-2 border border-gray-500 text-left">Rating Output</td>
              <td className="p-2 border border-gray-500 text-center">PS(kw)/RPM</td>
              <td className="p-2 border border-gray-500 text-center">260(191) / 2,000</td>
              <td className="p-2 border border-gray-500 text-center">330(242) / 2,100</td>
            </tr>
            {/* ... more rows ... */}
            <tr>
  <td className="p-2 border border-gray-500 text-left">No. of Cylinders</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">6 in Line</td>

</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Bore × Stroke</td>
  <td className="p-2 border border-gray-500 text-center">mm</td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">130 × 140</td>
  
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Displacement</td>
  <td className="p-2 border border-gray-500 text-center">cc</td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">11,149</td>
  
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Aspiration</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">TC</td>
  <td className="p-2 border border-gray-500 text-center">TCI</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Compression ratio</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">16 : 1</td>
  <td className="p-2 border border-gray-500 text-center">16.5 : 1</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Firing order</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">1-5-3-6-2-4</td>
  <td className="p-2 border border-gray-500 text-center">1-5-3-6-2-4</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Fuel injection type</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">Bosch type with all speed control</td>
  
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Fuel consumption</td>
  <td className="p-2 border border-gray-500 text-center">ℓ / h</td>
  <td className="p-2 border border-gray-500 text-center">45.6</td>
  <td className="p-2 border border-gray-500 text-center">57.9</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Lubrication oil pan capacity</td>
  <td className="p-2 border border-gray-500 text-center">ℓ</td>
  <td className="p-2 border border-gray-500 text-center">35</td>
  <td className="p-2 border border-gray-500 text-center">35</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Lubrication oil pan pressure</td>
  <td className="p-2 border border-gray-500 text-center">Kg / cm²</td>
  <td className="p-2 border border-gray-500 text-center">Idle: 1.5~3, Full: 3~5</td>
  <td className="p-2 border border-gray-500 text-center">Idle: 1.5~3, Full: 3~5</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Starting System</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">Electric starting by starter motor</td>
 
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Start motor capacity</td>
  <td className="p-2 border border-gray-500 text-center">V-KW</td>
  <td className="p-2 border border-gray-500 text-center">24-5.5</td>
  <td className="p-2 border border-gray-500 text-center">24-5.5</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Alternator capacity</td>
  <td className="p-2 border border-gray-500 text-center">V-A</td>
  <td className="p-2 border border-gray-500 text-center">24-40</td>
  <td className="p-2 border border-gray-500 text-center">24-40</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Cooling system</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">Indirect sea water cooling with heat exchanger</td>

</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Cooling water capacity</td>
  <td className="p-2 border border-gray-500 text-center">ℓ</td>
  <td className="p-2 border border-gray-500 text-center">60</td>
  <td className="p-2 border border-gray-500 text-center">60</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Fresh water pump type</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">Centrifugal type, driven by gear</td>
 
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Sea water pump type</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">Rubber impeller type, driven by gear</td>
 
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Engine size (L×W×H)</td>
  <td className="p-2 border border-gray-500 text-center">mm</td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">1,849 × 901 × 1,142</td>
 
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Engine dry weight (Without Transmission)</td>
  <td className="p-2 border border-gray-500 text-center">kg</td>
  <td className="p-2 border border-gray-500 text-center">960</td>
  <td className="p-2 border border-gray-500 text-center">1020</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">STD gear ratio(Optional)</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">2.09, 2.42, 2.82, 3.19, (3.81)</td>
  <td className="p-2 border border-gray-500 text-center">1.97, 2.57, 3.08, 3.46, (4.04, 4.48)</td>
</tr>

          </tbody>
        </table>
      </div>
    );
    
  }

  function renderTab6() {
    return (
      <div className="w-full p-4">
        <table className="w-full table-auto border border-gray-900">
          <thead className="bg-blue-900 text-white">
            <tr>
              <th colSpan={3} className="p-2 border border-gray-500 uppercase">
                ENGINE SPECIFICATIONS
              </th>
            </tr>
            <tr>
              <th className="p-2 border border-gray-500">Model</th>
              <th className="p-2 border border-gray-500">Unit</th>
              <th className="p-2 border border-gray-500">HD614TA</th>
            </tr>
          </thead>
          <tbody className="font-light">
            <tr>
              <td className="p-2 border border-gray-500 text-left">Engine type</td>
              <td className="p-2 border border-gray-500 text-left"></td>
              <td className="p-2 border border-gray-500 text-center">
                4Cycle, Vertical, Water cooled diesel engine
              </td>
            </tr>
            <tr>
              <td className="p-2 border border-gray-500 text-left">Rating Output</td>
              <td className="p-2 border border-gray-500 text-center">PS(kw)/RPM</td>
              <td className="p-2 border border-gray-500 text-center">
                410(302) / 2,000
              </td>
            </tr>
            <tr>
  <td className="p-2 border border-gray-500 text-left">No. of Cylinders</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">6 in Line</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Bore x Stroke</td>
  <td className="p-2 border border-gray-500 text-center">mm</td>
  <td className="p-2 border border-gray-500 text-center">122 x 142</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Displacement</td>
  <td className="p-2 border border-gray-500 text-center">cc</td>
  <td className="p-2 border border-gray-500 text-center">9,960</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Aspiration</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">TCI</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Compression ratio</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">17.0 : 1</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Firing order</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">1-5-3-6-2-4</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Fuel injection type</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">EUI</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Fuel consumption</td>
  <td className="p-2 border border-gray-500 text-center">ℓ / h</td>
  <td className="p-2 border border-gray-500 text-center">72</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Lubrication oil pan capacity</td>
  <td className="p-2 border border-gray-500 text-center">ℓ</td>
  <td className="p-2 border border-gray-500 text-center">40</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Lubrication oil pan pressure</td>
  <td className="p-2 border border-gray-500 text-center">Kg / cm²</td>
  <td className="p-2 border border-gray-500 text-center">Idle : 1.5~3, Full: 3~5</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Starting System</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">Electric starting by starter motor</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Start motor capacity</td>
  <td className="p-2 border border-gray-500 text-center">V-KW</td>
  <td className="p-2 border border-gray-500 text-center">24-6</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Alternator capacity</td>
  <td className="p-2 border border-gray-500 text-center">V-A</td>
  <td className="p-2 border border-gray-500 text-center">24-80</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Cooling system</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">Indirect sea water cooling with heat exchanger</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Cooling water capacity</td>
  <td className="p-2 border border-gray-500 text-center">ℓ</td>
  <td className="p-2 border border-gray-500 text-center">70</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Fresh water pump type</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">Centrifugal type, driven by gear</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Sea water pump type</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">Rubber impeller type, driven by gear</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Engine size (L×W×H)</td>
  <td className="p-2 border border-gray-500 text-center">mm</td>
  <td className="p-2 border border-gray-500 text-center">1,842 × 955 × 1,227</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Engine dry weight (Without Transmission)</td>
  <td className="p-2 border border-gray-500 text-center">kg</td>
  <td className="p-2 border border-gray-500 text-center">1,108</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">STD gear ratio(Optional)</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">2.09, 2.51, 3.08, 3.43, (4.04, 4.48)</td>
</tr>

            {/* ... additional rows ... */}
          </tbody>
        </table>
      </div>
    );
    
    
  }
  function renderTab7() {

    return (
      <div className="w-full p-4">
        <table className="w-full table-auto border border-gray-900">
          <thead className="text-white bg-blue-900">
            <tr>
              <th colSpan={3} className="p-2 border border-gray-500 uppercase">
                ENGINE SPECIFICATIONS
                </th>
              </tr>
            <tr>
              <th className="p-2 border border-gray-500">Model</th>
              <th className="p-2 border border-gray-500">Unit</th>
              <th className="p-2 border border-gray-500">HD612TA5</th>
            </tr>
          </thead>
          <tbody className="font-light">
            <tr>
              <td className="p-2 border border-gray-500 text-left">Engine type</td>
              <td className="p-2 border border-gray-500 text-left"></td>
              <td className="p-2 border border-gray-500 text-center">
                4Cycle, Vertical, Water cooled diesel engine
              </td>
            </tr>
            <tr>
              <td className="p-2 border border-gray-500 text-left">Rating Output</td>
              <td className="p-2 border border-gray-500 text-center">PS(kw)/RPM</td>
              <td className="p-2 border border-gray-500 text-center">
                500(367) / 2,000
              </td>
            </tr>
            <tr>
              <td className="p-2 border border-gray-500 text-left">No. of Cylinders</td>
              <td className="p-2 border border-gray-500 text-left"></td>
              <td className="p-2 border border-gray-500 text-center">
                6 in Line
              </td>
            </tr>
            <tr>
              <td className="p-2 border border-gray-500 text-left">Bore x Stroke</td>
              <td className="p-2 border border-gray-500 text-center">mm</td>
              <td className="p-2 border border-gray-500 text-center">
                130 x 155
              </td>
            </tr>
            <tr>
              <td className="p-2 border border-gray-500 text-left">Displacement</td>
              <td className="p-2 border border-gray-500 text-center">cc</td>
              <td className="p-2 border border-gray-500 text-center">
                12,344
              </td>
            </tr>
            {/* ... more rows ... */}
            <tr>
  <td className="p-2 border border-gray-500 text-left">Aspiration</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">TCI</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Compression ratio</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">17.2 : 1</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Firing order</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">1-5-3-6-2-4</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Fuel injection type</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">EUI</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Fuel consumption</td>
  <td className="p-2 border border-gray-500 text-center">ℓ / h</td>
  <td className="p-2 border border-gray-500 text-center">88.9</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Lubrication oil pan capacity</td>
  <td className="p-2 border border-gray-500 text-center">ℓ</td>
  <td className="p-2 border border-gray-500 text-center">40</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Lubrication oil pan pressure</td>
  <td className="p-2 border border-gray-500 text-center">Kg / cm²</td>
  <td className="p-2 border border-gray-500 text-center">Idle : 1.5, Full: 3~5</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Starting System</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">Electric starting by starter motor</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Start motor capacity</td>
  <td className="p-2 border border-gray-500 text-center">V-KW</td>
  <td className="p-2 border border-gray-500 text-center">24-6</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Alternator capacity</td>
  <td className="p-2 border border-gray-500 text-center">V-A</td>
  <td className="p-2 border border-gray-500 text-center">24-80</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Cooling system</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">Indirect sea water cooling with heat exchanger</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Cooling water capacity</td>
  <td className="p-2 border border-gray-500 text-center">ℓ</td>
  <td className="p-2 border border-gray-500 text-center">80</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Fresh water pump type</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">Centrifugal type, driven by gear</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Sea water pump type</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">Rubber impeller type, driven by gear</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Engine size (L×W×H)</td>
  <td className="p-2 border border-gray-500 text-center">mm</td>
  <td className="p-2 border border-gray-500 text-center">1,893 × 940 × 1,275</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Engine dry weight (Without Transmission)</td>
  <td className="p-2 border border-gray-500 text-center">kg</td>
  <td className="p-2 border border-gray-500 text-center">1,250</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">STD gear ratio(Optional)</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td className="p-2 border border-gray-500 text-center">2.09, 2.51, 3.08, 3.43, (4.04,4.48)</td>
</tr>

          </tbody>
        </table>
      </div>
    );
    
  }
  function renderTab8() {
    return (
      <div className="w-full p-4">
        <table className="w-full table-auto border border-gray-900">
          <thead className="bg-blue-900 text-white">
            <tr>
              <th colSpan={4} className="p-2 border border-gray-500 uppercase">
                ENGINE SPECIFICATIONS
              </th>
            </tr>
            <tr className="bg-blue-900 text-white">
              <th className="p-2 border border-gray-500 text-left">Model</th>
              <th className="p-2 border border-gray-500 text-center">Unit</th>
              <th className="p-2 border border-gray-500 text-center">HD615TA</th>
              <th className="p-2 border border-gray-500 text-center">HD615TA6</th>
            </tr>
          </thead>
          <tbody className="font-light">
            <tr>
              <td className="p-2 border border-gray-500 text-left">Engine type</td>
              <td className="p-2 border border-gray-500 text-center"></td>
              <td className="p-2 border border-gray-500 text-center" colSpan={2}>
                4Cycle, Vertical, Water cooled diesel engine
              </td>
            </tr>
            <tr>
              <td className="p-2 border border-gray-500 text-left">Rating Output</td>
              <td className="p-2 border border-gray-500 text-center">PS(kw)/RPM</td>
              <td className="p-2 border border-gray-500 text-center">550(404) / 2,000</td>
              <td className="p-2 border border-gray-500 text-center">600(441) / 2,000</td>
            </tr>
            <tr>
              <td className="p-2 border border-gray-500 text-left">No. of Cylinders</td>
              <td className="p-2 border border-gray-500 text-center"></td>
              <td colSpan={2} className="p-2 border border-gray-500 text-center">6 in Line</td>
             
            </tr>
            <tr>
              <td className="p-2 border border-gray-500 text-left">Bore x Stroke</td>
              <td className="p-2 border border-gray-500 text-center">mm</td>
              <td colSpan={2} className="p-2 border border-gray-500 text-center">130 x 160</td>
         
            </tr>
            <tr>
              <td className="p-2 border border-gray-500 text-left">Displacement</td>
              <td className="p-2 border border-gray-500 text-center">cc</td>
              <td colSpan={2} className="p-2 border border-gray-500 text-center">12,742</td>
           
            </tr>
            {/* ... additional rows ... */}
            <tr>
  <td className="p-2 border border-gray-500 text-left">Aspiration</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">TCI</td>

</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Compression ratio</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">17.2 : 1</td>

</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Firing order</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">1-5-3-6-2-4</td>

</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Fuel injection type</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">EUI</td>

</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Fuel consumption</td>
  <td className="p-2 border border-gray-500 text-center">ℓ / h</td>
  <td className="p-2 border border-gray-500 text-center">102.3</td>
  <td className="p-2 border border-gray-500 text-center">107</td>
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Lubrication oil pan capacity</td>
  <td className="p-2 border border-gray-500 text-center">ℓ</td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">40</td>

</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Lubrication oil pan pressure</td>
  <td className="p-2 border border-gray-500 text-center">Kg / cm²</td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">Idle : 1.5~3, Full: 3~5</td>

</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Starting System</td>
  <td className="p-2 border border-gray-500 text-center">-</td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">Electric starting by starter motor</td>

</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Start motor capacity</td>
  <td className="p-2 border border-gray-500 text-center">V-KW</td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">24-6</td>

</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Alternator capacity</td>
  <td className="p-2 border border-gray-500 text-center">V-A</td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">24-80</td>
 
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Cooling system</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">Indirect sea water cooling with heat exchanger</td>

</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Cooling water capacity</td>
  <td className="p-2 border border-gray-500 text-center">ℓ</td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">80</td>

</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Fresh water pump type</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">Centrifugal type, driven by gear</td>
 
</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Sea water pump type</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">Rubber impeller type, driven by gear</td>

</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Engine size (L×W×H)</td>
  <td className="p-2 border border-gray-500 text-center">mm</td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">1,893 × 940 × 1,275</td>

</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">Engine dry weight (Without Transmission)</td>
  <td className="p-2 border border-gray-500 text-center">kg</td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">1,280</td>

</tr>
<tr>
  <td className="p-2 border border-gray-500 text-left">STD gear ratio(Optional)</td>
  <td className="p-2 border border-gray-500 text-center"></td>
  <td colSpan={2} className="p-2 border border-gray-500 text-center">1.97, 2.44, 2.93, 3.40, (4.08,4.52)</td>

</tr>

          </tbody>
        </table>
      </div>
    );
    
    
    
  }

  return (
    <>
      <div className="">
        <section className="h-screen relative">
          <img
            src="../../../../images/Boat pages/Hyundai/CoverImage.jpg"
            className="absolute inset-0 w-full h-full object-cover"
            alt="Background"
          />
          <div className="container z-2 mx-auto relative p-2 py-28 md:py-46">
            <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
              <div className="lg:col-span-8 md:col-span-7 md:mb-16 ">
                <div className="md:mr-6">
                  <div
                    className="p-6 md:p-0 font-bold text-white text-6xl lg:text-7xl"
                    style={{
                      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)",
                      position: "relative",
                    }}
                  >
                    <span
                      className="hidden md:block "
                      style={{
                        position: "absolute",
                        top: -10,
                        left: 0,
                        height: "3px",
                        width: "25%",
                        backgroundColor: "white",
                      }}
                    ></span>
                    HYUNDAI MARINE ENGINE AND GENERATOR LINEUP
                    <span className="hidden md:block absolute left-0 w-1/4 h-[3px] bg-white"></span>
                  </div>
                  <div className="w-screen p-6 h-auto md:p-0  md:w-3/4">
                    <p className="text-white text-base md:text-3xl text-justify mt-6">
                 Exlplore our extensive range of Hyundai Marine Engines and Generators, setting new standards for efficiency, reliability and durability, and sustainable, eco-friendly performance. Dicover how Hyundai's cutting-edge technology is readliy available through us. and sail into the future of maritime excellence.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container font-bold text-blue-800 lg:leading-normal leading-normal text-2xl lg:text-5xl mb-2 uppercase">
            <div className="md:ml-20">
              <span className="relative">
                <span className="absolute -bottom-2 left-0 w-3/4 h-1 bg-yellow-500"></span>
                <span className="text-blue-800">MARINE DEISE</span>
                <span className="absolute -top-2 left-0 w-3/4 h-1 bg-yellow-500 text-blue-800"></span>
              </span>
              L ENGINE
            </div>
          </div>
          <div className="container mx-auto">
  <div className="flex overflow-x-auto p-2 gap-2 md:justify-center">
    {sampleTabData.map((tab: any) => (
      <div
        key={tab.id}
        className={`p-2 cursor-pointer gap-4 rounded-md ${
          activeTab.id === tab.id
            ? "bg-yellow-500 uppercase font-bold text-black mb-4 shadow-xl"
            : "bg-gray-300 uppercase mb-4"
        }`}
        onClick={() => handleTabClick(tab)}
      >
        {tab.title}
      </div>
    ))}
  </div>
</div>


          <div className="md:flex container md:mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 p-4">
              <div className="md:p-10">
                {/* {activeTab.content} */}
                <img
                  src={activeTab.image}
                  className="w-full h-auto"
                  alt="Background"
                />
                <div className="grid grid-cols-1">
                  <div className="flex justify-center items-center mx-auto">
                    <div className="font-bold">
                      {activeTab?.thankolhu1?.title}
                    </div>
                    <div className="font-light">
                      {activeTab?.thankolhu1?.description}
                    </div>
                  </div>
                  <div className="flex justify-center items-center mx-auto">
                    <div className="font-bold">
                      {activeTab?.thankolhu2?.title}
                    </div>
                    <div>{activeTab?.thankolhu2?.description}</div>
                  </div>
                  {/* <div className="flex justify-center items-center mx-auto">
                    <div className="font-bold">
                      {activeTab?.thankolhu3?.title}
                    </div>
                    <div>{activeTab?.thankolhu3?.description}</div>
                  </div>{" "}
                  <div className="flex justify-center items-center mx-auto">
                    <div className="font-bold">
                      {activeTab?.thankolhu4?.title}
                    </div>
                    <div>{activeTab?.thankolhu4?.description}</div>
                  </div> */}
                </div>
                <img
                  src={activeTab.engine}
                  className="w-full h-auto"
                  alt="Background"
                />{" "}
              </div>
              <div className="">{renderTableForTab(activeTab.title)}</div>
            </div>
          </div>
        </section>
        {/* <Generator /> */}
      </div>
    </>
  );
};

export default Hyundai;
