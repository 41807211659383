interface FeatureProps {
  features: {
    name?: string;
    description?: string;
    icon?: any;
  }[];
}
function Feature({ features }: FeatureProps) {
  return (
    <div className="">
      <div className="mx-auto max-w-xl px-6 lg:max-w-7xl lg:px-8">
        <dl className="grid grid-cols-1 gap-8 lg:grid lg:grid-cols-5">
          {features.map((feature, index) => (
            <div key={index}>
              <dt>
                {/* <div className="flex h-12 w-12 items-center justify-center rounded-xl bg-blue-800 text-white">
                  <feature.icon className="h-8 w-8" aria-hidden="true" />
                </div> */}
                <p className="mt-6 text-base font-semibold leading-8 tracking-tight text-gray-900">
                  {feature.name}
                </p>
              </dt>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}

export default Feature;
