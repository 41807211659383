import React from "react";
import Section from "../../../components/Boat/Section";
import GeneralDesign from "../sections/generalDesign";

const Interceptor = () => {
  return (
    <>
      <div className="lg:mx-auto lg:grid-cols-1 mt-12">
        <div className="flex container mx-auto">
          <GeneralDesign
            asluTitle="aslu title here"
            describe="descbie bessel"
            title="KF38 Interceptor"
            content="The KF-38 Interceptor is an exceptional vessel that combines speed, maneuverability, and comfort to create the ultimate full cabin interceptor. With its sleek and hydrodynamic design, the vessel is built to achieve maximum speed while ensuring unmatched comfort and protection for the crew."
            imageSrc="../../../../images/Boat pages/KF38 Harbor Patrol/1.png"
            imageSrc2="../../../../images/Boat pages/KF38 Harbor Patrol/2.png"
            imageSrc3="../../../../images/Boat pages/KF38 Harbor Patrol/3.png"
            imageSrc4="../../../../images/Boat pages/KF38 Harbor Patrol/4.png"
          />
        </div>
      </div>
    </>
  );
};

export default Interceptor;
