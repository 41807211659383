import React from "react";
import GeneralDesign from "../sections/generalDesign";
const BattheliFishing = () => {
  return (
    <>
      <div className="lg:mx-auto lg:grid-cols-1 mt-12">
        <div className="flex">
          <GeneralDesign
            asluTitle="Battheli 38 - Fishing Vessel"
            describe="a new breed of high-performance speed boat"
            title="Power, beauty, and innovation"
            content="With a rich fishing history, our country's connection to the sea is undeniable. To complete our company's boat lineup, we proudly introduce a state-of-the-art fishing vessel. Leveraging recent industry advancements, we present a modern marvel designed to captivate the eye and enhance your fishing experience. This vessel seamlessly combines aesthetics and functionality, offering ultimate comfort in various conditions. Featuring a lavish 2-cushion sofa seating arrangement, its semi-open design provides protection from the elements while allowing you to relish the surroundings. Positioned at the rear are two bait holders on both port and starboard sides, ensuring ample bait storage and easy access for a seamless fishing experience. The boat is also equipped with numerous fishing rod holders and ample storage, catering to all your fishing equipment needs. Boasting a fuel-efficient diesel outboard engine, an 800-liter fuel tank, and an 80-liter water tank, this vessel empowers you to embark on extended fishing journeys with maximum efficiency."
            imageSrc="../../../../images/Boat pages/Battheli 38 - Fishing/boatpage/1.png"
            imageSrc2="../../../../images/Boat pages/Battheli 38 - Fishing/boatpage/2.png"
            imageSrc3="../../../../images/Boat pages/Battheli 38 - Fishing/boatpage/3.png"
            imageSrc4="../../../../images/Boat pages/Battheli 38 - Fishing/boatpage/4.png"
            imageSrc5="../../../../images/Boat pages/Battheli 38 - Fishing/boatpage/5.png"
            imageSrc6="../../../../images/Boat pages/Battheli 38 - Fishing/boatpage/6.png"
            imageSrc7="../../../../images/Boat pages/Battheli 38 - Fishing/boatpage/7.png"
            imageSrc8="../../../../images/Boat pages/Battheli 38 - Fishing/boatpage/8.png"
            imageSrc9="../../../../images/Boat pages/Battheli 38 - Fishing/boatpage/9.png"
            imageSrc10="../../../../images/Boat pages/Battheli 38 - Fishing/boatpage/10.png"
            imageSrc11="../../../../images/Boat pages/Battheli 38 - Fishing/boatpage/11.png"
            imageSrc12="../../../../images/Boat pages/Battheli 38 - Fishing/boatpage/12.png"
          />
        </div>
      </div>
    </>
  );
};

export default BattheliFishing;
