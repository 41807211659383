import React from "react";
import Section from "../../../components/Boat/Section";
import General from "../sections/general";

const VipSecurityVessel = () => {
  return (
    <>
      {/* <Info /> */}
      <div className="lg:mx-auto lg:grid-cols-1 mt-12">
        <div className="flex container mx-auto">
          <Section id="section1" label="">
            <General
              title="KF38 VIP SECURITY VESSEL"
              content="Introducing the KF-38 VIP transport vessel, the epitome of luxury and functionality on the water. With a spacious and versatile design, this vessel is built to cater to the needs of VIP personnel while ensuring maximum efficiency and safety. </br> The safety and security of the passengers is paramount, and the vessel is equipped with a suite of advanced safety equipment for use in case of emergency, as well as sophisticated navigation and communication technologies. These features ensure that the vessel can maintain constant interconnectivity, enabling safe and secure transport operations.
              "
              imageSrc="https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty-300x240.jpg"
            />
          </Section>
        </div>
      </div>
    </>
  );
};

export default VipSecurityVessel;
