import React from "react";
import GeneralDesign from "../sections/generalDesign";
const Passenger55 = () => {
  return (
    <>
      <div className="lg:mx-auto lg:grid-cols-1 mt-12">
        <div className="flex">
          <GeneralDesign
            asluTitle="55' Passenger Vessel"
            describe="Unforgettable Journeys"
            title="Smooth Transitions"
            content="This passenger transfer vessel, redifines the art of comfortable and efficent transportation at sea. Designed to seamlessly transfer passengers to their destinations, it embodies our unwavering commitment to safety, precision, and innovation. Explore the features that make this vessel a game-changer in maritime passenger transport, setting a new standard for your journeys on the water."
            imageSrc="../../../../images/Boat pages/55' Passenger/1.png"
            imageSrc2="../../../../images/Boat pages/55' Passenger/2.png"
            imageSrc3="../../../../images/Boat pages/55' Passenger/3.png"
            imageSrc4="../../../../images/Boat pages/55' Passenger/4.png"
            imageSrc6="../../../../images/Boat pages/55' Passenger/6.png"
            imageSrc7="../../../../images/Boat pages/55' Passenger/7.png"
            imageSrc8="../../../../images/Boat pages/55' Passenger/8.png"
            imageSrc9="../../../../images/Boat pages/55' Passenger/9.png"
            imageSrc10="../../../../images/Boat pages/55' Passenger/10.png"
            imageSrc11="../../../../images/Boat pages/55' Passenger/11.png"
            imageSrc12="../../../../images/Boat pages/55' Passenger/12.png"
            imageSrc13="../../../../images/Boat pages/55' Passenger/13.png"
            imageSrc14="../../../../images/Boat pages/55' Passenger/14.png"
            imageSrc15="../../../../images/Boat pages/55' Passenger/15.png"
            imageSrc16="../../../../images/Boat pages/55' Passenger/16.png"
            imageSrc17="../../../../images/Boat pages/55' Passenger/17.png"
            // imageSrc18="../../../../images/Boat pages/55' Passenger/18.png"


          />
        </div>
      </div>
    </>
  );
};

export default Passenger55;
