// HeroSection.js
import React from "react";

interface GeneralProps {
  asluTitle?: string;
  title: string;
  describe?: string;
  content: string;
  imageSrc: string;
  imageSrc2?: string;
  imageSrc3?: string;
  imageSrc4?: string;
  imageSrc5?: string;
  imageSrc6?: string;
  imageSrc7?: string;
  imageSrc8?: string;
  imageSrc9?: string;
  imageSrc10?: string;
  imageSrc11?: string;
  imageSrc12?: string;
  imageSrc13?: string;
  imageSrc14?: string;
  imageSrc15?: string;
  imageSrc16?: string;
  imageSrc17?: string;
  imageSrc18?: string;
  imageSrc19?: string;
  imageSrc20?: string;
  imageSrc21?: string;
  imageSrc22?: string;
  imageSrc23?: string;
  imageSrc24?: string;


}

function GeneralDesign({
  asluTitle,
  title,
  describe,
  content,
  imageSrc,
  imageSrc2,
  imageSrc3,
  imageSrc4,
  imageSrc5,
  imageSrc6,
  imageSrc7,
  imageSrc8,
  imageSrc9,
  imageSrc10,
  imageSrc11,
  imageSrc12,
  imageSrc13,
  imageSrc14,
  imageSrc15,
  imageSrc16,
  imageSrc17,
  imageSrc18,
  imageSrc19,
  imageSrc20,
  imageSrc21,
  imageSrc22,
  imageSrc23,
  imageSrc24,


}: GeneralProps) {
  return (
    <div className="h-full bg-black">
      <div className="uppercase text-white text-5xl py-2 px-6 mt-12">
        {asluTitle}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 bg-white">
        <div className="w-full p-4 lg:p-6">
          <h2 className="text-xl font-bold text-gray-900 sm:text-3xl">
            {title}
          </h2>
          {describe && <p className="text-main">{describe}</p>}
          <p className="mt-6 text-base leading-7 text-gray-600 text-justify">
            {content}
          </p>
        </div>
        <div className="justify-end float-right col-span-2">
          {/* rounded */}
          {imageSrc && (
            <img
              src={imageSrc}
              className="w-full h-full  md:rounded-none"
              alt="Background"
            />
          )}
        </div>
      </div>


      <div className="grid grid-cols-1 lg:grid-cols-3 bg-white">
        {/* Check and render imageSrc2 */}
        {imageSrc2 && (
          <div>
            <img
              src={imageSrc2}
              className="w-full h-full md:rounded-none bg-white"
              alt="Background"
            />
          </div>
        )}

        {/* Check and render imageSrc3 */}
        {imageSrc3 && (
          <div>
            <img
              src={imageSrc3}
              className="w-full h-full md:rounded-none bg-white"
              alt="Background"
            />
          </div>
        )}

        {/* Check and render imageSrc4 */}
        {imageSrc4 && (
          <div>
            <img
              src={imageSrc4}
              className="w-full h-full md:rounded-none bg-white"
              alt="Background"
            />
          </div>
        )}
        {/* Check and render imageSrc5 */}
        {imageSrc5 && (
          <div>
            <img
              src={imageSrc5}
              className="w-full h-full md:rounded-none bg-white"
              alt="Background"
            />
          </div>
        )}
        {/* Check and render imageSrc6 */}
        {imageSrc6 && (
          <div>
            <img
              src={imageSrc6}
              className="w-full h-full md:rounded-none bg-white"
              alt="Background"
            />
          </div>
        )}
        
        {/* Check and render imageSrc7 */}
        {imageSrc7 && (
          <div>
            <img
              src={imageSrc7}
              className="w-full h-full md:rounded-none bg-white"
              alt="Background"
            />
          </div>
        )}

        {/* Check and render imageSrc8 */}
        {imageSrc8 && (
          <div>
            <img
              src={imageSrc8}
              className="w-full h-full md:rounded-none bg-white"
              alt="Background"
            />
          </div>
        )}

        {/* Check and render imageSrc9 */}
        {imageSrc9 && (
          <div>
            <img
              src={imageSrc9}
              className="w-full h-full md:rounded-none bg-white"
              alt="Background"
            />
          </div>
        )}


        {/* Check and render imageSrc10*/}
        {imageSrc10 && (
          <div>
            <img
              src={imageSrc10}
              className="w-full h-full md:rounded-none bg-white"
              alt="Background"
            />
          </div>
        )}
        {/* Check and render imageSrc11 */}
        {imageSrc11 && (
          <div>
            <img
              src={imageSrc11}
              className="w-full h-full md:rounded-none bg-white"
              alt="Background"
            />
          </div>
        )}

        {/* Check and render imageSrc12 */}
        {imageSrc12 && (
          <div>
            <img
              src={imageSrc12}
              className="w-full h-full md:rounded-none bg-white"
              alt="Background"
            />
          </div>
        )}

        {/* Check and render imageSrc13 */}
        {imageSrc13 && (
          <div>
            <img
              src={imageSrc13}
              className="w-full h-full md:rounded-none bg-white"
              alt="Background"
            />
          </div>
        )}

        {/* Check and render imageSrc14 */}
        {imageSrc14 && (
          <div>
            <img
              src={imageSrc14}
              className="w-full h-full md:rounded-none bg-white"
              alt="Background"
            />
          </div>
        )}
        {/* Check and render imageSrc15 */}
        {imageSrc15 && (
          <div>
            <img
              src={imageSrc15}
              className="w-full h-full md:rounded-none bg-white"
              alt="Background"
            />
          </div>
        )}
        {/* Check and render imageSrc16 */}
        {imageSrc16 && (
          <div>
            <img
              src={imageSrc16}
              className="w-full h-full md:rounded-none bg-white"
              alt="Background"
            />
          </div>
        )}
        
        {/* Check and render imageSrc17 */}
        {imageSrc17 && (
          <div>
            <img
              src={imageSrc17}
              className="w-full h-full md:rounded-none bg-white"
              alt="Background"
            />
          </div>
        )}

        {/* Check and render imageSrc18 */}
        {imageSrc18 && (
          <div>
            <img
              src={imageSrc18}
              className="w-full h-full md:rounded-none bg-white"
              alt="Background"
            />
          </div>
        )}

        {/* Check and render imageSrc19 */}
        {imageSrc19 && (
          <div>
            <img
              src={imageSrc19}
              className="w-full h-full md:rounded-none bg-white"
              alt="Background"
            />
          </div>
        )}
        {/* Check and render imageSrc20 */}
        {imageSrc20 && (
          <div>
            <img
              src={imageSrc20}
              className="w-full h-full md:rounded-none bg-white"
              alt="Background"
            />
          </div>
        )}
        {/* Check and render imageSrc21 */}
        {imageSrc21 && (
          <div>
            <img
              src={imageSrc21}
              className="w-full h-full md:rounded-none bg-white"
              alt="Background"
            />
          </div>
        )}
        
        {/* Check and render imageSrc22 */}
        {imageSrc22 && (
          <div>
            <img
              src={imageSrc22}
              className="w-full h-full md:rounded-none bg-white"
              alt="Background"
            />
          </div>
        )}
        
        {/* Check and render imageSrc23 */}
        {imageSrc23 && (
          <div>
            <img
              src={imageSrc23}
              className="w-full h-full md:rounded-none bg-white"
              alt="Background"
            />
          </div>
        )}
        
        {/* Check and render imageSrc24 */}
        {imageSrc24 && (
          <div>
            <img
              src={imageSrc24}
              className="w-full h-full md:rounded-none bg-white"
              alt="Background"
            />
          </div>
        )}



        </div>
        </div>
      );
    
}

export default GeneralDesign;
