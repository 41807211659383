import React, { ReactNode } from "react";
import NewMega from "../Navbar/NewMega";
import Navigation from "../Navbar/navigation";
import Footer from "../Boat/Footer";

interface NavigationItem {
  name: string;
  href: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <NewMega />
      {/* <Navigation /> */}

      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
