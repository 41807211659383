import React from "react";
import GeneralDesign from "./generalDesign";
const COX = () => {
  return (
    <>
      <div className="">
        <section className="h-screen relative">
          <img
            src="../../../../images/Boat pages/COX/CoverImage.jpg"
            className="absolute inset-0 w-full h-full object-cover"
            alt="Background"
          />

          <div className="container z-2 mx-auto relative p-2 py-28 md:py-46">
            <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
              <div className="lg:col-span-8 md:col-span-7 md:mb-16 ">
                <div className="md:mr-6">
                  <div
                    className="p-6 md:p-0 font-bold text-white text-6xl lg:text-7xl"
                    style={{
                      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)",
                      position: "relative",
                    }}
                  >
                    <span
                      className="hidden md:block "
                      style={{
                        position: "absolute",
                        top: -10,
                        left: 0,
                        height: "3px",
                        width: "25%",
                        backgroundColor: "white",
                      }}
                    ></span>
                    COX CXO300 ENGINES
                  </div>
                  <div className="w-screen p-6 md:p-0  md:w-2/4">
                    <div className="text-white font-thin leading-normal text-lg md:text-3xl uppercase relative">
                      <span className="">Pioneering Pe</span>
                      rformance
                      <span className="hidden md:block absolute top-12 left-0 w-1/2 h-[3px] bg-white"></span>
                    </div>

                    <p className="text-white text-base md:text-md text-justify mt-6">
                      Designed with sustainable innovation in mind, the CX0300
                      is a fuel efficient, eco-friendly diesel outboard engine
                      designed for maximum speed. Explore the careful
                      engineering and flawless details COX designers put into
                      this powerful outboard motor{" "}
                    </p>
                  </div>

                  <img
                    src="../../../../images/Boat pages/COX/Engine1.png"
                    className="hidden md:block absolute right-24 w-1/3 top-48"
                    alt="Background"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container p-10">
          <img
            src="../../../../images/Boat pages/COX/Engine1.png"
            className="w-full  md:hidden"
            alt="Background"
          />
        </div>

        <section className="container p-10 mx-auto h-auto md:h-[80vh]">
          <div className="grid grid-cols-3 md:grid-cols-6">
            <div className="col-span-2">
              <div className="font-bold text-main lg:leading-normal leading-normal text-2xl lg:text-5xl mb-2 uppercase">
                <span className="relative">
                  <span className="absolute -bottom-2 left-0 w-3/4 h-1 bg-red-700"></span>
                  <span className="">key fe</span>
                  <span className="absolute -top-2 left-0 w-3/4 h-1 bg-red-700"></span>
                </span>
                atures
              </div>

              <img
                src="../../../../images/Boat pages/COX/Engine2.jpg"
                className="w-full md:w-auto md:h-1/2 left-0"
                alt="Background"
              />
            </div>
            <div className="col-span-4">
              <ul className="pl-5  md:p-10  mt-14">
                <div className=" w-10 h-10 absolute -mt-12 md:-mt-4">
                  <img
                    src="../../../../images/Boat pages/COX/COX Ribbon.svg"
                    alt="background"
                  ></img>
                </div>
                <li className="mb-4 md:mx-16">
                  <strong className="block font-bold text-lg text-main">
                    Unparalleled Performance
                  </strong>
                  The CXO3000 delivers industry-leading torque speed and power
                </li>
                <li className="mb-4 md:mx-16">
                  <strong className="block font-bold text-lg text-main">
                    Fuel Efficiency
                  </strong>
                  Experience fuel savings and extended range with this
                  high-efficiency diesel engine
                </li>{" "}
                <li className="mb-4 md:mx-16">
                  <strong className="block font-bold text-lg text-main">
                    Reliability
                  </strong>
                  COX Powertrain's Engineering excelence ensures robust and
                  dependable performace
                </li>{" "}
                <li className="mb-4 md:mx-16">
                  <strong className="block font-bold text-lg text-main">
                    Environmental Friendliness
                  </strong>
                  Reduced emissions and owl environmental impact
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="md:h-screen">
          <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-gray-200">
              <img
                src="../../../../images/Boat pages/COX/Engine4.png"
                className="w-full h-auto"
                alt="Background"
              />
            </div>
            <div className="bg-gray-200">
              <img
                src="../../../../images/Boat pages/COX/Engine5.png"
                className="w-full h-auto"
                alt="Background"
              />
            </div>
            <div className="bg-gray-200">
              <img
                src="../../../../images/Boat pages/COX/Engine6.png"
                className="w-full h-auto"
                alt="Background"
              />
            </div>{" "}
          </div>
        </section>

        <section className="h-screen container mx-auto my-10">
          <div className="grid grid-cols-1 lg:grid-cols-3 bg-white">
            <div className="justify-end float-right col-span-2">
              <img
                src="../../../../images/Boat pages/COX/SlideImage.jpg"
                className="w-full h-full rounded-lg md:rounded-none"
                alt="Background"
              />
            </div>
            <div className="w-full p-4 lg:p-6">
              <h2 className="text-xl font-bold text-gray-900 sm:text-3xl">
                Propel your Adventures with the CX0300 Marine Engine{" "}
              </h2>
              <p className="mt-6 text-base leading-7 text-gray-600 text-justify">
                The CX030 si a game-changing diesel outboard engine that ofers
                exceptional power, efficiency, and reliability. Designed for a
                variety of marine applications, this engine brings a new level
                of performance ot the boating world.
              </p>
              <p className="mt-6 text-base leading-7 text-gray-600 text-justify">
                In the world of marine propulsion, the CX030 outboard marine
                engine yb Cox Powertrain stands out as atrue game-changer.
                Boasting an aray of cuting-edge features and benefits, ti
                redefines the way boaters and maritime professionals experience
                the open water.d{" "}
              </p>
            </div>{" "}
          </div>
        </section>
      </div>
    </>
  );
};

export default COX;
