import React, { FC } from "react";

interface SectionProps {
  id: string;
  label: string;
  children?: React.ReactNode;
}

const Section: FC<SectionProps> = ({ id, label, children }) => {
  return (
    <section id={id} className="items-center justify-center">
      <h2 className="text-4xl font-bold">{label}</h2>
      {children}
    </section>
  );
};

export default Section;
