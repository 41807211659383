import React, { Fragment } from "react";
import { CheckIcon, MinusIcon } from "@heroicons/react/20/solid";

interface Feature {
  name: string;
  spec: string;
}

interface Section {
  name: string;
  features: Feature[];
}

interface SectionsProp {
  sections: Section[];
}

interface Props extends SectionsProp {}

const Specs: React.FC<Props> = ({ sections }) => {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <p className="text-3xl font-bold tracking-tight text-gray-900">
            Specifications
          </p>
        </div>

        {/* xs to lg */}
        <div className="mx-auto mt-12 max-w-md space-y-8 sm:mt-16 lg:hidden">
          {sections.map((section) => (
            <section
              key={section.name}
              className="rounded-xl bg-gray-400/5 ring-1 ring-inset ring-gray-200 p-8"
            >
              <ul
                role="list"
                className="mt-10 space-y-4 text-sm leading-6 text-gray-900"
              >
                {section.features.map((feature) => (
                  <li key={feature.name} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-main"
                      aria-hidden="true"
                    />
                    <span>
                      {feature.name}{" "}
                      {feature.spec ? (
                        <span className="text-sm leading-6 text-gray-500">
                          ({feature.spec})
                        </span>
                      ) : null}
                    </span>
                  </li>
                ))}
              </ul>
            </section>
          ))}
        </div>
        {/* lg+ */}
        <div className="isolate hidden lg:block">
          <div className="relative -mx-8">
            <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
              <colgroup>
                <col className="w-1/2" />
                <col className="w-1/2" />
              </colgroup>
              <tbody>
                {sections.map((section, sectionIdx) => (
                  <React.Fragment key={section.name}>
                    <tr>
                      <th
                        scope="colgroup"
                        colSpan={2}
                        className={`pt-${
                          sectionIdx === 0 ? 8 : 16
                        } pb-4 text-sm font-semibold leading-6 text-gray-900`}
                      >
                        {section.name}
                        <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10" />
                      </th>
                    </tr>
                    {section.features.map((feature) => (
                      <tr key={feature.name}>
                        <th
                          scope="row"
                          className="py-4 text-sm font-normal leading-6 text-gray-900"
                        >
                          {feature.name}
                          <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
                        </th>
                        <td className="px-6 py-4 xl:px-8">
                          <div className="text-center text-sm leading-6 text-gray-500">
                            {feature.spec}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Specs;
