import React from "react";
import GeneralDesign from "../sections/generalDesign";
const HabourPatrol = () => {
  return (
    <>
      <div className="lg:mx-auto lg:grid-cols-1 mt-12">
        <div className="flex">
          <GeneralDesign
            asluTitle="KF 38 HARBOR CRAFT"
            describe="The Vigilant Defender of Waters"
            title="Safety at Sea"
            content="Introducing the Kalhuoh Fummi 38 Harbour Patrol Craft, the ultimate solution for
              safeguarding and patrolling the inshore waters and harbours. This vessel is a highly
              versatile and manoeuvrable craft, designed to provide maximum comfort and protection
              to all personnel on board, while also delivering exceptional performance and efficiency.
              Equipped with the latest navigation and communication systems, the crew can stay
              informed and always connected, ensuring optimal safety and efficiency."
            imageSrc="../../../../images/Boat pages/KF38 Harbor Patrol/1.png"
            imageSrc2="../../../../images/Boat pages/KF38 Harbor Patrol/2.png"
            imageSrc3="../../../../images/Boat pages/KF38 Harbor Patrol/3.png"
            imageSrc4="../../../../images/Boat pages/KF38 Harbor Patrol/4.png"
            imageSrc5="../../../../images/Boat pages/KF38 Harbor Patrol/5.png"
            imageSrc6="../../../../images/Boat pages/KF38 Harbor Patrol/6.png"
            imageSrc7="../../../../images/Boat pages/KF38 Harbor Patrol/7.png"
            imageSrc8="../../../../images/Boat pages/KF38 Harbor Patrol/8.png"
            imageSrc9="../../../../images/Boat pages/KF38 Harbor Patrol/10.png"
            imageSrc10="../../../../images/Boat pages/KF38 Harbor Patrol/11.png"
            imageSrc11="../../../../images/Boat pages/KF38 Harbor Patrol/12.png"
            imageSrc12="../../../../images/Boat pages/KF38 Harbor Patrol/13.png"
            imageSrc13="../../../../images/Boat pages/KF38 Harbor Patrol/14.png"
            imageSrc14="../../../../images/Boat pages/KF38 Harbor Patrol/15.png"
            imageSrc15="../../../../images/Boat pages/KF38 Harbor Patrol/16.png"
            imageSrc16="../../../../images/Boat pages/KF38 Harbor Patrol/17.png"
          />
        </div>
      </div>
    </>
  );
};

export default HabourPatrol;
