import { useState } from "react";

interface Feature {
  name: string;
  icon: React.ElementType;
  image: string;
}

interface SeatingProps {
  features: Feature[];
}

const Seating: React.FC<SeatingProps> = ({ features }) => {
  const [selectedFeature, setSelectedFeature] = useState(features[0]);

  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="md:mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:ml-auto lg:pl-4 lg:pt-4">
            <div className="lg:max-w-lg ">
              <p className="mt-2 lg:ml-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Seating
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div
                    key={feature.name}
                    className={`relative ${
                      selectedFeature === feature ? "font-semibold" : ""
                    }`}
                    onClick={() => setSelectedFeature(feature)}
                  >
                    <dt className="inline-flex gap-4 font-semibold text-gray-900 cursor-pointer">
                      <span
                        className={` ${
                          selectedFeature === feature ? "text-main" : ""
                        }`}
                      >
                        {feature.name}
                      </span>
                    </dt>{" "}
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className="flex items-start justify-end lg:order-first">
            <img
              src={selectedFeature.image}
              alt={selectedFeature.name}
              className="w-[48rem rounded-md"
              width={2432}
              height={1442}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Seating;
