import React from "react";
const Mala = () => {
  return (
    <>
      <div className="">
        <section className="h-screen relative">
          <img
            src="../../../../images/Boat pages/Partners/MHW.jpg"
            className="absolute inset-0 w-full h-full object-cover"
            alt="Background"
          />

          <div className="container z-2 mx-auto relative p-2 py-28 md:py-46">
            <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
              <div className="lg:col-span-8 md:col-span-7 md:mb-16 ">
                <div className="md:mr-6">
                  <div
                    className="p-6 md:p-0 font-bold text-white text-6xl lg:text-7xl"
                    style={{
                      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)",
                      position: "relative",
                    }}
                  >
                    <span
                      className="hidden md:block "
                      style={{
                        position: "absolute",
                        top: -10,
                        left: 0,
                        height: "3px",
                        width: "25%",
                        backgroundColor: "rgb(234, 179, 8)",
                      }}
                    ></span>
                    MALA HARDWARE
                  </div>
                  <div className="w-screen p-6 md:p-0  md:w-2/4">
                    <div className="text-white font-thin leading-normal text-lg md:text-3xl uppercase relative">
                      <span className="text-yellow-500">
                        HABOUR THE BEST TOOLS
                      </span>
                      <span className="hidden md:block absolute top-12 left-0 w-1/2 h-[3px] bg-yellow-500"></span>
                    </div>

                    <p className="text-black text-base md:text-md text-justify mt-6">
                      Designed with sustainable innovation in mind, the CX0300
                      is a fuel efficient, eco-friendly diesel outboard engine
                      designed for maximum speed. Explore the careful
                      engineering and flawless details COX designers put into
                      this powerful outboard motor{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container p-10">
          <img
            src="../../../../images/Boat pages/COX/Engine1.png"
            className="w-full  md:hidden"
            alt="Background"
          />
        </div>

        <section className="container p-10 mx-auto h-auto md:h-[80vh]">
          <div className="grid grid-cols-3 md:grid-cols-6">
            <div className="col-span-2">
              <div className="font-bold text-main lg:leading-normal leading-normal text-2xl lg:text-5xl mb-2 uppercase">
                <span className="relative">
                  <span className="absolute -bottom-2 left-0 w-3/4 h-1 bg-yellow-500"></span>
                  <span className="">INTRODUCTION</span>
                  <span className="absolute -top-2 left-0 w-3/4 h-1 bg-yellow-500"></span>
                </span>
              </div>
            </div>
            <div className="col-span-full">
              <div className="mt-6">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit
                natus quia quaerat, saepe numquam accusantium! Saepe consequatur
                rerum iste, totam et tempora eaque, vero deleniti, aperiam
                aliquid debitis doloribus molestias! Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Sed minus, ipsa temporibus, illum
                eius ad, animi provident itaque fugit quos nam perferendis vero
                eligendi ut nisi id atque deserunt blanditiis?
              </div>
              <div className="mt-6">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit
                natus quia quaerat, saepe numquam accusantium! Saepe consequatur
                rerum iste, totam et tempora eaque, vero deleniti, aperiam
                aliquid debitis doloribus molestias! Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Sed minus, ipsa temporibus, illum
                eius ad, animi provident itaque fugit quos nam perferendis vero
                eligendi ut nisi id atque deserunt blanditiis?
              </div>
              <div className="mt-6">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit
                natus quia quaerat, saepe numquam accusantium! Saepe consequatur
                rerum iste, totam et tempora eaque, vero deleniti, aperiam
                aliquid debitis doloribus molestias! Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Sed minus, ipsa temporibus, illum
                eius ad, animi provident itaque fugit quos nam perferendis vero
                eligendi ut nisi id atque deserunt blanditiis?
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Mala;
